import React from 'react'
import { usePosterReducers } from '../../../../../redux/getdata/usePostReducer'
import { useParams } from 'react-router-dom'
import { dateFormat } from '../../../../utils/common'

const ViewCatering = () => {
    const{catering_list}=usePosterReducers()
    const param=useParams()
    const detail=catering_list?.data?.find(catering=>param.id===catering.id)
    
  return (
    <div className="p-2 px-3 overflow-auto h-full">
      <p className="font-semibold text-lg text-gray-500 my-2 md:my-0">
        Catering Details
      </p>

  
       
        <div className="mt-3 md:mt-0">
          <div className="flex gap-2 my-4 text-sm">
            <p className="min-w-44 font-medium">Event Name  </p>:
            <p>{detail?.name}</p>
          </div>

          <div className="flex gap-2 my-4 text-sm">
            <p className="min-w-44 font-medium">User Email  </p>:
            <p>{detail?.email}</p>
          </div>
          <div className="flex gap-2 my-4 text-sm">
            <p className="min-w-44 font-medium">Phone no  </p>:
            <p>{detail?.phone}</p>
          </div>
          <div className="flex gap-2 my-4 text-sm">
            <p className="min-w-44 font-medium">Event Date  </p>:
            <p>{dateFormat(detail?.event_date?detail.event_date:'')}</p>
          </div>
          <div className="flex gap-2 my-4 text-sm">
            <p className="min-w-44 font-medium">Delivery Date  </p>:
            <p>{dateFormat(detail?.delivery_date?detail.delivery_date:'')}</p>
          </div>
          <div className="flex gap-2 my-4 text-sm">
            <p className="min-w-44 font-medium">Delivery Address  </p>:
            <p>{detail?.delivery_address}</p>
          </div>
          <div className="flex gap-2 my-4 text-sm">
            <p className="min-w-44 font-medium">Delivery Method  </p>:
            <p>{detail?.delivery_method}</p>
          </div>
          <div className="flex gap-2 my-4 text-sm">
            <p className="min-w-44 font-medium">Delivery Instructions</p>:
            <p>{detail?.delivery_instructions}</p>
          </div>
          <div className="flex gap-2 my-4 text-sm">
            <p className="min-w-44 font-medium">Guest Count</p>:
            <p>{detail?.guest_count}</p>
          </div>
          
          <div className="flex gap-2 my-4 text-sm">
            <p className="min-w-44 font-medium">Reuested Items</p>:
            <p>{detail?.items_requested}</p>
          </div>
          
          <div className="flex gap-2 my-4 text-sm">
            <p className="min-w-44 font-medium">Description</p>:
            <p>{detail?.description}</p>
          </div>
          <div className="flex gap-2 my-4 text-sm">
            <p className="min-w-44 font-medium">Found us at</p>:
            <p>{detail?.how_did_you_find}</p>
          </div>
          <div className="flex gap-2 my-4 text-sm">
            <p className="min-w-44 font-medium">Created Date </p>:
            <p>{dateFormat(detail?.created_at?detail.created_at:'')}</p>
          </div>

          <div className="flex gap-2 my-4 text-sm">
            <p className="min-w-44 font-medium"> Status </p>:
            <p>{detail?.is_active?'Active':'Inactive'}</p>
          </div>
          
         
        </div>
    
    </div>
  )
}

export default ViewCatering