/* eslint-disable */
import { useEffect, useState } from "react";
import List from "./list";
import Form from "./form";
import TableLayout from "../../../components/TableLayout";
import WarnPopup from "../../../components/popup/WarnPopup";
import {
  IToggleModalTypes,
  IToggleWarnModalTypes,
  IVariantTypes,
} from "../../../../utils/types";
import {
  deleteVariantContaint,
  editStatusContaint,
  initialPage,
} from "../../../../utils/staticdata";
import { useWebSocket } from "../../../../api/websocket/WebSocketContext";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  category_lists,
  delete_variant,
  edit_variant,
  variant_lists,
} from "../../../../api/websocket/payload";
import {
  setCategorylist,
  setVariantlist,
} from "../../../../../redux/actions/action";

const Variant = () => {
  const { send, receivedMessage, isConnect } = useWebSocket();

  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState<IToggleModalTypes>("hide");
  const [showWarn, setShowWarn] = useState<IToggleWarnModalTypes>("hide");
  const [editData, setEditData] = useState<any>();
  const [id, setid] = useState("");
  const [EditStatus, setEditStatus] = useState<IVariantTypes>();

  const toggleWarn = (e: any, item: any, type: string) => {
    if (type === "delete") {
      setid(item);
      setShowWarn("delete");
    }
    if (type === "hide") {
      setShowWarn("hide");
    }

    if (type === "edit") {
      const Data: any = {
        variant_id: item?.id,
        is_active: e.target.checked ? "1" : "0",
        name: item?.name,
        description: item?.description,
        category_ids: item?.categories_data
        ?.map((elm: any) => elm.category_id)?.join(","),
      };

      setEditStatus(Data);
      setShowWarn("edit");
    }
  };

  const toggleModal = (state: IToggleModalTypes) => {
    setShowModal(state);
  };

  const onEdit = (item: IVariantTypes, state: IToggleModalTypes) => {
    toggleModal(state);
    setEditData(item);
  };

  const deleteVariant = () => {
    if (isConnect) {
      const message = { ...delete_variant, request: { variant_id: id } };
      send(message);
    }
  };

  const editVariant = () => {
    const payload = { ...edit_variant, request: EditStatus };
    send(payload);
  };

  useEffect(() => {
    if (isConnect) {
      if (receivedMessage?.url === "variant_listing") {
        if (receivedMessage?.response?.status === 200)
          dispatch(setVariantlist(receivedMessage?.response?.data));
        if (receivedMessage?.response?.status === 400)
          dispatch(setVariantlist(initialPage));
      }
      if (receivedMessage?.url === "variant_add") {
        if (receivedMessage?.response?.status === 200) {
          send(variant_lists);
          toast.success(receivedMessage?.response?.msg);
        }

        toggleModal("hide");
      }
      if (receivedMessage?.url === "variant_update") {
        if (receivedMessage?.response?.status === 200) {
          send(variant_lists);
          toast.success(receivedMessage?.response?.msg);
        }

        toggleModal("hide");
        setShowWarn("hide");
      }
      if (receivedMessage?.url === "category_listing") {
        dispatch(setCategorylist(receivedMessage?.response?.data));
      }
      if (receivedMessage?.url === "variant_delete") {
        if (receivedMessage?.response?.status === 200) {
          send(variant_lists);
          toast.success(receivedMessage?.response?.msg);
        }
        setShowWarn("hide");
      }

      // }
    }
  }, [receivedMessage, isConnect]);

  useEffect(() => {
    if (isConnect) {
      const message = category_lists;
      send(message);
    }
  }, [isConnect]);

  useEffect(() => {
    if (isConnect) {
      const message = variant_lists;
      send(message);
    }
  }, [isConnect]);

  useEffect(() => {
    return () => {
      variant_lists.request = {
        filter: "all",
        limit: 10,
        page: 1,
        search: "",
      };
    };
  }, []);

  return (
    <TableLayout
      isAdd
      isFilter
      title={"Variant"}
      payload={variant_lists}
      handleOpen={() => toggleModal("add")}
    >
      <List onEdit={onEdit} edit={toggleModal} toggleWarn={toggleWarn} />
      <Form
        isEditable
        showModal={showModal}
        toggleModal={toggleModal}
        editData={editData}
      />
      <WarnPopup
        onSubmit={showWarn === "delete" ? deleteVariant : editVariant}
        containt={
          showWarn === "delete" ? deleteVariantContaint : editStatusContaint
        }
        showModal={showWarn}
        toggleModal={toggleWarn}
      />
    </TableLayout>
  );
};

export default Variant;
