import React, { useEffect } from "react";
import FormPopup from "../../../components/popup/FormPopup";
import InputField from "../../../components/input/InputField";
import { IAddProductSubscriptionTypes, IToggleModalTypes } from "../../../../utils/types";
import {  Controller, useForm } from "react-hook-form";
import { useWebSocket } from "../../../../api/websocket/WebSocketContext";
import {  add_product_subscription_list, edit_product_subscription_list} from "../../../../api/websocket/payload";
// import Dropdown from "../../../components/dropdown";
import Dropdown from "../../../components/DropdownN";

interface IForm {
  showModal: string;
  toggleModal: (state: IToggleModalTypes) => void;
  editData: IAddProductSubscriptionTypes;
  isEditable: boolean;
}
const Form: React.FC<IForm> = (prop) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    watch,
  } = useForm<IAddProductSubscriptionTypes>();
  const { send } = useWebSocket();

  const formContaint = {
    title: `${prop.showModal === "edit" ? "Edit" : "Add"} Product Subscription`,
    submit: "Submit",
    close: "Cancel",
  };

  useEffect(() => {
    if (prop.showModal === "edit") {
      reset(prop.editData); // Resets the form values to prop.editData
    } else {
      reset({ name: "", discount: "" }); // Resets the form values to prop.editData
    }
  }, [prop.showModal, reset, prop.editData]);

  const optionProductSubscription = [{
    value: "day",
    label: "day",
  },{
    value: "week",
    label: "week",
  },{
    value: "month",
    label: "month",
  }];

  const onSubmit = (data: IAddProductSubscriptionTypes) => {
      if (prop.showModal === "add") {
          let payload = {
            ...add_product_subscription_list,
            request: {
              name: data?.name,
              interval: data?.interval,
              discount: Number(data.discount),
            },
          };
          send(payload);
      }
      if (prop.showModal === "edit") {
        const Data = {
          subscription_id: prop.editData.id,
          name: data.name,
          interval: data?.interval,
          is_active: data.is_active ? "1" : "0",
          discount: Number(data.discount),
        };
        const payload = { ...edit_product_subscription_list, request: Data };

        send(payload);
      }
  };

  return (
    <div className="product_subscription">
      <FormPopup
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        containt={formContaint}
        showModal={prop.showModal}
        toggleModal={prop.toggleModal}
      >
      <Controller
        name="name"
        control={control}
        // defaultValue={defaultValue}
        rules={{
          required: "name is required",
        }}
        render={({ field }) => (
          <Dropdown
            required
            label="Product Subscription"
            options={optionProductSubscription}
            isDynamic
            defaultValue="Product Subscription"
            value={field.value}
            onChange={field.onChange}
            name={field.name}
            className="py-2"
            errors={errors.name}
            // isSearch
          />
        )}
      />
      {/* {watch("name") !== "" && ( */}
        <InputField
          name={"Interval"}
          register={register("interval", {
            required: watch("name") + " is required",
            pattern: {
              value: /^\d+(\.\d+)?$/,
              message: watch("name") + " must be a number",
            },
            validate: {
              greaterThanZero: value => parseFloat(value) > 0 || `${watch("name")} must be greater than 0`,
              max365: value => watch("name") === "day" ? parseFloat(value) <= 365 || watch("name") +" cannot be more than 365" :
              watch("name") === "week" ? parseFloat(value) <= 52 || watch("name") +" cannot be more than 52" : parseFloat(value) <= 12 || watch("name") +" cannot be more than 12" 
            }
          })}
          required
          placeholder={"Enter Interval"}
          inputClassName="h-8"
          error={errors.interval}
      />
      {/* )} */}

       <InputField
          name={"Discount (%)"}
          register={register("discount", {
            required: "discount is required",
            pattern: {
              value: /^\d+(\.\d+)?$/,
              message: "discount must be a number",
            },
          })}
          required
          placeholder={"Enter Discount"}
          inputClassName="h-8"
          error={errors.discount}
        />
      </FormPopup>
    </div>
  );
};

export default Form;
