/* eslint-disable */
import { useEffect, useRef, useState } from "react";
import InputField from "../../../components/input/InputField";
import FormLayout from "../../../components/FormLayout";
import { Controller, useForm } from "react-hook-form";
import { IProductAddTypes } from "../../../../utils/types";
import { useWebSocket } from "../../../../api/websocket/WebSocketContext";
import {
  setCategorylist,
  setKitlist,
  setVariantAlllist,
} from "../../../../../redux/actions/action";
import { useDispatch } from "react-redux";
import {
  add_product,
  category_lists,
  edit_product,
  kit_lists,
  product_details,
  variant_product_lists,
} from "../../../../api/websocket/payload";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addformContaint,
  editformContaint,
} from "../../../../utils/staticdata";
import UploadImage from "../../../components/UploadImage";
import DropdownOld from "../../../components/dropdown";
import Dropdown from "../../../components/DropdownN";

interface IForm {}

const AddProduct = (prop: IForm) => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    watch,
    setError,
    formState: { errors },
    clearErrors,
  } = useForm<IProductAddTypes>();
  const { send, receivedMessage, isConnect } = useWebSocket();
  const param = useParams();
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const [categoryName, setcategoryName] = useState([]);
  const [variantName, setvariantName] = useState([]);
  const [kitName, setkitName] = useState([]);
  const [Type, setType] = useState("");
  const [initialImageUrls, setInitialImageUrls] = useState<string[]>([]);
  let page_url = location.pathname.split("/")[3];
  const navigate = useNavigate();
  let type = [
    { value: "single", label: "Single" },
    { value: "variable", label: "Variable" },
    { value: "kit", label: "Kit" },
  ];

  const product_type = watch("product_type");

  useEffect(() => {
    if (isConnect) {
      if (receivedMessage) {
        if (receivedMessage.url === "category_listing") {
          dispatch(setCategorylist(receivedMessage.response.data.data));
          if (receivedMessage?.response?.data?.data?.length !== 0) {
            const filteredData = receivedMessage?.response?.data?.data?.filter(
              (item: any) =>
                item.type === (Type === "single" ? "single" : "bundle")
            );
            const extractedNames = filteredData?.map((item: any) => ({
              label: item.name,
              value: item.id,
            }));
          
            setcategoryName(extractedNames);
          }
        }
        if (receivedMessage.url === "variant_listing") {
          if(receivedMessage?.request?.limit === 5000) {
            dispatch(setVariantAlllist(receivedMessage.response.data.data));
          };
          if (receivedMessage?.response?.data?.data?.length !== 0) {
            const extractedNames = receivedMessage?.response?.data?.data
            ?.filter((elm: { is_active: boolean; }) => elm?.is_active === true)
            .sort((a: { quantity: number; }, b: { quantity: number; }) => b.quantity - a.quantity)
            .map((item: any) => ({ 
              label: item.name + ` - (${item?.quantity})  $${(Number(item?.price) / 100).toFixed(2)}`, 
              value: item.id 
            }));
            setvariantName(extractedNames);
          }
        }

        if (receivedMessage.url === "kit_listing") {
          dispatch(setKitlist(receivedMessage?.response?.data));
          if (receivedMessage?.response?.data?.data?.length !== 0) {
            const extractedNames = receivedMessage?.response?.data?.data?.filter((elm: { is_active: boolean; }) => elm?.is_active === true).map(
              (item: any) => ({ label: item.name, value: item.id })
            );
            setkitName(extractedNames);
          }
        }

        if (receivedMessage.url === "product_add") {
          if (receivedMessage?.response?.status === 200)
            toast.success(receivedMessage.response.msg);

          navigate("/product-management/product");
        }
        if (receivedMessage.url === "product_update") {
          if (receivedMessage?.response?.status === 200)
            navigate("/product-management/product");
        }
        if (receivedMessage.url === "product_get") {
          if (receivedMessage?.response?.status === 200) {
            setInitialImageUrls([receivedMessage?.response?.data?.image]);
            reset({
              ...receivedMessage.response.data,
              regular_price: (
                Number(receivedMessage?.response?.data?.regular_price) / 100
              ).toString(),
              sale_price: (
                Number(receivedMessage?.response?.data?.sale_price) / 100
              ).toString(),
              variant_ids: receivedMessage?.response?.data?.variant?.map((elm: { variant_id: any; }) => elm?.variant_id),
              category_id: receivedMessage?.response?.data?.category_id,
              sequence_no: receivedMessage?.response?.data?.sequence_no,
              product_type: receivedMessage?.response?.data?.product_type,
            });
            setType(receivedMessage.response.data.product_type)
          }
        }
      }
    }
  }, [receivedMessage]);

  useEffect(() => {
    if (
      isConnect &&
      (Type === "single" || Type === "variable" || Type === "kit")
    ) {
      const message1 = category_lists;
      send(message1);
    }
  }, [isConnect, dispatch, Type]);

  useEffect(() => {
    if (isConnect && Type === "kit") {
      const message3 = kit_lists;
      send(message3);
    }
  }, [isConnect, dispatch, Type]);

  useEffect(() => {
    if (isConnect && Type === "variable") {
      const message = variant_product_lists;
      send(message);
    }
  }, [isConnect, dispatch, Type]);

  useEffect(() => {
    if (isConnect) {
      if (page_url === "edit-product") {
        const message2 = {
          ...product_details,
          request: { product_id: param.id },
        };

        send(message2);
      }
    }
  }, [isConnect]);

  const onSubmit = (data: IProductAddTypes) => {

    if ("image" in data) {
      if (!data.image) {
      if (ref.current) ref.current.scrollIntoView({ behavior: "smooth" });
        setError("image", {
          type: "manual",
          message: "Image is required",
        });
        return;
      }

    } else {
      setError("image", {
        type: "manual",
        message: "Image is required",
      });

      if (ref.current) ref.current.scrollIntoView({ behavior: "smooth" });
      return;
    }

    if (page_url === "edit-product") {
      const payload = {
        product_id: param.id,
        // category_id:data?.category_id,
        name: data.name,
        short_description: data.short_description,
        description: data.description,
        weight: data.weight,
        dimension: data.dimension,
        purchase_note: data.purchase_note,
        sequence_no: Number(data.sequence_no),
        sale_price:
          data.sale_price !== "" ? Number(data.sale_price) * 100 : null,
        regular_price:
          data.regular_price !== "" ? Number(data.regular_price) * 100 : null,
        ingredients: data.ingredients,
        image: data.image,
        tax: data.tax,
        in_stock: data.in_stock,
        exclude_from_pack_kit:data.exclude_from_pack_kit,
        has_shipping: data.has_shipping,
        has_subscription: data.has_subscription,
        nutrition_facts: data.nutrition_facts,
        variant_ids: data?.variant_ids?.join(","),
        product_type: data?.product_type,
        kit_id: data?.kit_id,
        is_active: data.is_active ? "1" : "0",
      };

      const paramfilter = Object.entries(payload).filter((elm) => {
        if(elm[1] !== "") {
            return elm;
        }
      });
      const req = { ...edit_product, request: Object.fromEntries(paramfilter) };
 
      send(req);
    }
    if (page_url !== "edit-product") {
      const req = {
        ...add_product,
        request: {
          ...data,
          variant_ids: data?.variant_ids?.join(","),
          sale_price:
            data.sale_price !== "" ? Number(data.sale_price) * 100 : null,
          regular_price:
            data.regular_price !== "" ? Number(data.regular_price) * 100 : null,
          sequence_no: Number(data.sequence_no),
          dimension:
            data.dimension.length !== "" ||
            data.dimension.width !== "" ||
            data.dimension.height !== ""
              ? {
                  length:
                    data.dimension.length !== ""
                      ? Number(data.dimension.length)
                      : null,
                  width:
                    data.dimension.length !== ""
                      ? Number(data.dimension.width)
                      : null,
                  height:
                    data.dimension.length !== ""
                      ? Number(data.dimension.height)
                      : null,
                }
              : null,
        },
      };
      send(req);
    }
  };

  function handleSelect(type: string | number) {
    setType(type.toString());
  }

  interface Category {
    label: string;
    // other properties...
  }

  const GetCategoryName= () => {
    return categoryName?.filter((elm) => (elm as { value: string })?.value === watch("category_id"))[0];
  };

  const category = GetCategoryName() as Category | null;
  return (
    <>
      <FormLayout
        containt={
          page_url !== "edit-product" ? addformContaint : editformContaint
        }
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
      >
        <div>
          <div ref={ref}>
            <UploadImage
              initialImageUrls={initialImageUrls}
              singleUpload
              setValue={setValue}
              error={errors?.image}
              clearError={clearErrors}
              required
              name="image"
            />
          </div>
          <div className="lg:w-2/3 lg:flex mt-6 gap-2 my-2">
            {/* {page_url !== "edit-product" && ( */}
              {/* <> */}
                <Controller
                  name="product_type"
                  control={control}
                  rules={{
                    required: "Product type is required",
                  }}
                  disabled={page_url === "edit-product"}
                  render={({ field }) => (
                    <Dropdown
                      label="Product Type"
                      isDynamic
                      defaultValue="Product type"
                      options={type}
                      value={field.value}
                      onChange={(e: any) => {
                        field.onChange(e);
                        handleSelect(e); // Use the value from the event
                      }}
                      name={field.name}
                      className="py-2"
                      required
                      errors={errors.product_type}
                      // disabled={page_url === "edit-product"}
                    />
                  )}
                />
                {Type !== "" && (<>
                  {page_url === "edit-product" ? (<div className="py-2 maincustom-dropdonn">
                    <label className="dropdown-label font-medium text-gray-600 text-sm mb-[1px]">Category Name</label><br/>
                    {category?.label}
                  </div>) :
                   (
                   
                   <Controller
                    name="category_id"
                    control={control}
                    rules={{
                      required: "Category is required",
                    }}
                    render={({ field }) => (
                      <Dropdown
                        isDynamic
                        label="Category Name"
                        options={categoryName}
                        value={field.value}
                        onChange={field.onChange}
                        name={field.name}
                        className="py-2"
                        required
                        defaultValue="Category"
                        errors={errors.category_id}
                        disabled={page_url === "edit-product"}
                      />
                    )}
                  />
                 )} 
                </> 
                )} 
        
            {Type === "variable" ? (
              <div className="mt-2">
                <Controller
                name="variant_ids"
                control={control}
                // defaultValue="all"
                rules={{
                  required:
                    Type === "variable" ? "Variant name is required" : false,
                }}
                render={({ field }) => (
                  <DropdownOld
                    required
                    label="Variant Name"
                    options={variantName}
                    multiselect
                    value={field.value}
                    onChange={field.onChange}
                    name={field.name}
                    defaultValue="Variant"
                    isDynamic
                    className="py-2"
                    errors={errors.variant_ids}
                  />
                )}
              />
              </div>
            ) : (
              <></>
            )}

            {Type === "kit" ? (
              <Controller
                name="kit_id"
                control={control}
                // defaultValue="all"
                rules={{
                  required: Type === "kit" ? "Kit name is required" : false,
                }}
                render={({ field }) => (
                  <Dropdown
                    required
                    label="Kit Name"
                    options={kitName}
                    isDynamic
                    defaultValue="Kit"
                    value={field.value}
                    onChange={field.onChange}
                    name={field.name}
                    className="py-2"
                    errors={errors.kit_id}
                  />
                )}
              />
            ) : (
              <></>
            )}
          </div>
          <div className="lg:flex w-full gap-2 my-2">
            <InputField
              name={"Product Name"}
              placeholder={"Enter Product"}
              inputClassName="h-9 "
              className="w-full my-4 lg:my-0 "
              required
              error={errors.name}
              register={{
                ...register("name", {
                  required: "Product name is required",
                  maxLength: {
                    value: 256,
                    message: "Product limit cannot exceed 256 characters",
                  },
                }),
              }}
            />
            {Type === "single" && (
              <div className="flex w-full gap-2 ">
                {Type === "single" && (
                  <InputField
                    name={"Regular price"}
                    placeholder={"Enter Regular Price"}
                    inputClassName="h-9 "
                    required={Type !== "single" ? false : true}
                    error={errors.regular_price}
                    register={register("regular_price", {
                      required: Type !== "single" ? false : "Price is required",
                      pattern: {
                        value: /^\d+(\.\d+)?$/,
                        message: "Product limit must be a number",
                      },
                    })}
                    className="w-full my-4 lg:my-0"
                  />
                )}
                <InputField
                  name={"Sale Price"}
                  placeholder={"Enter Sale Price"}
                  inputClassName="h-9 "
                  error={errors.sale_price}
                  register={{
                    ...register("sale_price", {
                      pattern: {
                        value: /^\d+(\.\d+)?$/,
                        message: "Sale Price must be a number",
                      },
                    }),
                  }}
                  className="w-full my-4 lg:my-0"
                />
              </div>
            )}
          </div>
          <div className="lg:flex w-full gap-2 my-2 ">
            <InputField
              name={"Weight "}
              placeholder={"Enter Weight"}
              inputClassName="h-9 "
              className="w-full my-4 lg:my-0"
              required
              error={errors.weight}
              register={register("weight", {
                required: "Weight is required",
              
              })}
            />

            <InputField
              name={"Sequence Number "}
              placeholder={"Enter Sequence Number"}
              inputClassName="h-9 "
              className="w-full my-4 lg:my-0"
              required
              error={errors.sequence_no}
              register={register("sequence_no", {
                required: "Sequence Number is required",
                pattern: {
                  value: /^\d+(\.\d+)?$/,
                  message: "Sequence Number must be a number",
                },
              })}
            />

            <div className="flex gap-2 w-full ">
              <InputField
                name={"Length "}
                placeholder={"Enter Length"}
                inputClassName="h-9 "
                className="w-full my-4 lg:my-0"
                register={{
                  ...register("dimension.length", {
                    // required: "Length is required",
                    pattern: {
                      value: /^\d+(\.\d+)?$/,
                      message: "length must be a number",
                    },
                  }),
                }}
                error={errors.dimension?.length}
              />
              <InputField
                name={"Height "}
                placeholder={"Enter Height"}
                inputClassName="h-9 "
                className="w-full my-4 lg:my-0"
                register={{
                  ...register("dimension.height", {
                    // required: "Height is required",
                    pattern: {
                      value: /^\d+(\.\d+)?$/,
                      message: "height must be a number",
                    },
                  }),
                }}
                error={errors.dimension?.height}
              />
              <InputField
                name={"width "}
                placeholder={"Enter width"}
                inputClassName="h-9 "
                className="w-full my-4 lg:my-0"
                register={{
                  ...register("dimension.width", {
                    // required: "Width is required",
                    pattern: {
                      value: /^\d+(\.\d+)?$/,
                      message: "width must be a number",
                    },
                  }),
                }}
                error={errors.dimension?.width}
              />
            </div>
          </div>
          <div className="lg:flex w-full gap-2 my-2">
            <InputField
              name={"Product Short Description"}
              placeholder={"Enter product short description"}
              inputClassName="h-18"
              required
              error={errors.short_description}
              register={{
                ...register("short_description", {
                  required: "Short description is required",
                  maxLength: {
                    value: 2000,
                    message: "short description cannot exceed 2000 characters",
                  },
                }),
              }}
              useFor="textarea"
              className="w-full my-4 lg:my-0"
            />
            <InputField
              name={"Product Description"}
              placeholder={"Enter product description"}
              inputClassName="h-18 "
              className="w-full my-4 lg:my-0"
              required
              error={errors.description}
              register={{
                ...register("description", {
                  required: "Description is required",
                  maxLength: {
                    value: 2000,
                    message: "description cannot exceed 2000 characters",
                  },
                }),
              }}
              useFor="textarea"
            />
          </div>
          <div className="lg:flex w-full gap-2 my-2 ">
            <InputField
              name={"Product Ingredients"}
              placeholder={"Enter Product Ingredients"}
              inputClassName="h-18"
              error={errors.ingredients}
              register={{
                ...register("ingredients", {
                  maxLength: {
                    value: 2000,
                    message: "ingredients cannot exceed 2000 characters",
                  },
                }),
              }}
              useFor="textarea"
              className="w-full my-4 lg:my-0"
            />
            <InputField
              name={"Product Nutrition Facts"}
              placeholder={"Enter Product Nutrition Facts"}
              inputClassName="h-18 "
              className="w-full lg:my-0"
              error={errors.nutrition_facts}
              register={{
                ...register("nutrition_facts", {
                  maxLength: {
                    value: 2000,
                    message: "nutrition facts cannot exceed 2000 characters",
                  },
                }),
              }}
              useFor="textarea"
            />
          </div>
          <div className="flex lg:w-1/2 justify-between  gap-6 my-2 font-medium text-gray-600 text-sm ">
            <div>
              <p className="whitespace-nowrap">Stock Status</p>
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  {...register("in_stock")}
                />
                <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-500 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[1px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
            </div>
            <div>
              <p className="whitespace-nowrap">Shipping</p>
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  {...register("has_shipping")}
                />
                <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-500 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[1px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
            </div>
            <div>
              <p className="whitespace-nowrap">Subscription</p>
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  {...register("has_subscription")} />
                <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-500 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[1px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
            </div>
            {Type==='single'&&<div>
              <p className="whitespace-nowrap">Exclude from pack kit</p>
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  {...register("exclude_from_pack_kit")}
                />
                <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-500 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[1px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
            </div>}
            {page_url === "edit-product" && (
              <>
                <div>
                  <p className="whitespace-nowrap">Active Status</p>
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only peer"
                      {...register("is_active")}
                      // defaultChecked={item.is_active}
                      // onChange={(e) => props.toggleWarn(e,item,'edit')}
                    />
                    <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-500 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[1px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </div>
              </>
            )}
          </div>
          <InputField
            name={"Purchase Note"}
            placeholder={"Enter Purchase Note"}
            inputClassName="h-18"
            error={errors.purchase_note}
            register={{
              ...register("purchase_note", {
                maxLength: {
                  value: 2000,
                  message: "purchase note cannot exceed 2000 characters",
                },
              }),
            }}
            useFor="textarea"
            className="w-full my-4 lg:my-0"
          />
        </div>
      </FormLayout>
    </>
  );
};

export default AddProduct;
