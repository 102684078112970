import InputField from "../../components/input/InputField";
import {  useForm } from "react-hook-form";
import { ILoginTypes } from "../../../utils/types";
import Button from "../../components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthReq } from "../../../api/rest/fetchData";
import { APP_LOGIN_URL, DASHBOARD_URL } from "../../../constant/url";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import {
  setDeviceInfo,
  setIslogin,
  setProfile,
  setToken,
  setUserId,
} from "../../../../redux/actions/action";
import { toast } from "react-toastify";

const Form = () => {
  const { register, handleSubmit } = useForm<ILoginTypes>();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit = (data: ILoginTypes) => {
    const body = {
      device_type: "web",
      device_id: uuidv4(),
    };
    const payload = { ...data, ...body };
    AuthReq(APP_LOGIN_URL, payload).then((resp) => {
      if (resp?.status === 200) {
        toast.success(resp?.data?.msg)
        dispatch(setIslogin('yes'))
        dispatch(setProfile(resp.data?.data?.user));
        dispatch(setToken(resp.data?.data?.access_token));
        dispatch(setUserId(resp.data?.data?.user?.id));
        dispatch(
          setDeviceInfo({
            device_id: resp.data?.data?.device_id,
            device_type: resp.data?.data?.device_type,
          })
        );
        navigate(DASHBOARD_URL);
      }
    });
  };
  return (
    <form action="" onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-white  p-6 lg:px-8 lg:py-8 rounded-xl shadow-lg ">
        <p className="text-3xl text-center font-medium text-gray-700">Login</p>
        <InputField
          name={"Email"}
          placeholder={"Enter your email"}
          inputClassName=" h-9"
          className=" lg:w-96 w-80 "
          register={register("email")}
        />
        <InputField
          name={"Password"}
          placeholder={"Enter your password"}
          type="password"
          inputClassName="h-9"
          className=" lg:w-96 w-80"
          register={register("password")}
        />
        <Button
          type="submit"
          className={`bg-[#2737FF] py-2 text-center ${
            (location.pathname === "/" || location.pathname === "/login") &&
            "w-full"
          } my-5 flex justify-center `}
          label="Log In"
        />
      </div>
    </form>
  );
};

export default Form;


