/* eslint-disable */
import { useEffect, useState } from "react";
import TableLayout from "../../../components/TableLayout";
import WarnPopup from "../../../components/popup/WarnPopup";
import { IToggleWarnModalTypes } from "../../../../utils/types";
import { useWebSocket } from "../../../../api/websocket/WebSocketContext";
import { deleteCateringContaint } from "../../../../utils/staticdata";
import {
  delete_catering,
  catering_lists,
} from "../../../../api/websocket/payload";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setCateringlist } from "../../../../../redux/actions/action";
import List from "./list";

const Catering = () => {
  const { send, receivedMessage, isConnect } = useWebSocket();
  const dispatch = useDispatch();
  const [showWarn, setShowWarn] = useState<IToggleWarnModalTypes>("hide");
  const [id, setid] = useState("");
  useEffect(() => {
    if (isConnect) {
      if (receivedMessage) {
        if (receivedMessage?.url === catering_lists.url) {
          dispatch(setCateringlist(receivedMessage?.response?.data));
        }
        if (receivedMessage?.url === delete_catering.url) {
          if (receivedMessage?.response?.status === 200) {
            send(catering_lists)
            toast.success(receivedMessage?.response?.msg);
          }
          setShowWarn("hide");
        }
      }
    }
  }, [receivedMessage]);

  useEffect(() => {
    if (isConnect) {
      const message = catering_lists;
      send(message);
    }
  }, [isConnect, receivedMessage?.url]);

  useEffect(() => {
    return () => {
      catering_lists.request = {
        limit: 10,
        page: 1,
        search: "",
      };
    };
  }, []);

  const toggleWarn = (e: any, item: any, type: string) => {
    if (type === "delete") {
      setid(item);
      setShowWarn("delete");
    }
    if (type === "hide") {
      setShowWarn("hide");
    }
  };

  const deleteCatering = () => {
    if (isConnect) {
      const message = { ...delete_catering, request: { catering_id: id } };
      send(message);
    }
  };

  return (
    <TableLayout isAdd={false} title={"Catering"} payload={catering_lists}>
      <List toggleWarn={toggleWarn} />

      <WarnPopup
        onSubmit={deleteCatering}
        containt={deleteCateringContaint}
        showModal={showWarn}
        toggleModal={toggleWarn}
      />
    </TableLayout>
  );
};

export default Catering;
