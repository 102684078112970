import { createReducer } from "@reduxjs/toolkit";
import { Action, ActionTypes } from "../actions/action";
import { IUiInitalState } from "../../app/utils/types";


const initialData: IUiInitalState = {
  
  show_loader: false,
  isSocketConnected: false,

};

const uiReducer = createReducer(initialData, (builder) => {
  builder
    .addCase(ActionTypes.LOADER_SHOW, (state, action: Action) => {
      return { ...state, show_loader: action.payload };
    })
    builder
    .addCase(ActionTypes.SOCKETCONNECT, (state, action: Action) => {
      return { ...state, isSocketConnected: action.payload };
    })
    
});

export default uiReducer;
