import React from 'react'
import ListComp from '../../../components/ListComp'
import { CiEdit } from 'react-icons/ci'
import { AiOutlineDelete } from 'react-icons/ai'
import { usePosterReducers } from '../../../../../redux/getdata/usePostReducer';
import { dateFormat } from '../../../../utils/common';
import { product_subscription_lists } from '../../../../api/websocket/payload';
interface IList {
  edit: Function;
  toggleWarn: Function;
  onEdit:Function,
  
}
const List:React.FC<IList> = (props) => {
  const { product_subscription_list } = usePosterReducers();
  
    const header=['Name','Interval','Discount (%)','Created At','Status','Actions']
   

    
    
  return (
    <ListComp pages={product_subscription_list?.pagination} payLoad={product_subscription_lists} header={header}>
       {product_subscription_list?.data?.length !== 0 &&
          product_subscription_list?.data?.map((item, index) => {
            return(<tr key={index}  className="border-b text-sm rounded-md  ">
              <td className="p-3">{item.name}</td>
              <td>{item?.interval === null ? "-" : item?.interval}</td>
              <td className="p-3">{item.discount}</td>
              <td>{dateFormat(item.created_at)}</td>
              <td className="px-4  text-sm py-2">
                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    checked={item.is_active}
                    onChange={()=>{}}
                    onClick={(e) => props.toggleWarn(e,item,'edit')}
                   
                  />
                  <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-500 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[1px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
              </td>
              <td>
                <div className="flex justify-center items-center gap-2">
                  <button
                    onClick={() => props.onEdit(item,'edit')}
                    className="p-2 text-base text-black rounded-md"
                  >
                    <CiEdit />
                  </button>
                  <button
                    onClick={() => props.toggleWarn("",item.id,'delete')}
                    className="p-2  text-base text-black rounded-md"
                  >
                    <AiOutlineDelete />
                  </button>
                </div>
              </td>
            </tr>)
          })}
    </ListComp>
  )
}

export default List