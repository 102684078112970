import React, { useEffect, useRef, useState } from "react";
import FormPopup from "../../../components/popup/FormPopup";
import InputField from "../../../components/input/InputField";
import DatePicker from "react-datepicker";
import { IAddCommunityEvent, IToggleModalTypes } from "../../../../utils/types";
import { Controller, useForm } from "react-hook-form";
import { useWebSocket } from "../../../../api/websocket/WebSocketContext";
import {
  add_community_event,
  edit_community_event,
} from "../../../../api/websocket/payload";

import moment from "moment";
import UploadImage from "../../../components/UploadImage";

interface IForm {
  showModal: string;
  toggleModal: (state: IToggleModalTypes) => void;
  editData: IAddCommunityEvent;
  isEditable: boolean;
}
const Form: React.FC<IForm> = (prop) => {
  const {
    register,
    handleSubmit,
    reset,
    setError,
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<IAddCommunityEvent>();
  const { send } = useWebSocket();
  const today = new Date();
  const [endDate, setendDate] = useState<Date | null>(today);
  const formContaint = {
    title: `${prop.showModal === "edit" ? "Edit" : "Add"} Community Event`,
    submit: "Submit",
    close: "Cancel",
  };

  const [initialImageUrls, setInitialImageUrls] = useState<string[]>([]);

  useEffect(() => {
    if (prop.showModal === "edit") {
      setInitialImageUrls([prop?.editData?.image]);
      reset(prop.editData); // Resets the form values to prop.editData
    } else {
      setInitialImageUrls([]);
      reset(); // Resets the form values to prop.editData
    }
  }, [prop.showModal, reset, prop.editData]);
  const ref = useRef<HTMLDivElement>(null);
  const onSubmit = (data: IAddCommunityEvent) => {
    const formattedDate = data.start_time
      ? moment(data.start_time).format("YYYY-MM-DD HH:mm:ss")
      : "";

    const formattedDate1 = data.end_time
      ? moment(data.end_time).format("YYYY-MM-DD HH:mm:ss")
      : "";

    data.start_time = formattedDate;
    data.end_time = formattedDate1;

    if ("image" in data) {
      if (!data.image) {
        setError("image", {
          type: "manual",
          message: "Image is required",
        });
        return;
      }

      if (ref.current) ref.current.scrollIntoView({ behavior: "smooth" });
    } else {
      setError("image", {
        type: "manual",
        message: "Image is required",
      });

      if (ref.current) ref.current.scrollIntoView({ behavior: "smooth" });
      return;
    }

    if (prop.showModal === "add") {
      const payload = {
        ...add_community_event,
        request: data,
      };
      send(payload);
    }
    if (prop.showModal === "edit") {
      data.event_id = prop.editData.id;
      const payload = {
        ...edit_community_event,
        request: {
          event_id: prop.editData.id,
          title: data.title,
          description: data.description,
          country: data.country,
          state: data.state,
          image: data.image,
          address: data.address,
          start_time: data.start_time,
          end_time: data.end_time,
          is_active: data.is_active ? "1" : "0",
        },
      };
      send(payload);
    }
  };

  return (
    <>
      <FormPopup
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        containt={formContaint}
        showModal={prop.showModal}
        toggleModal={prop.toggleModal}
      >
        <div ref={ref}>
          <UploadImage
            required
            name="image"
            initialImageUrls={initialImageUrls}
            singleUpload
            setValue={setValue}
            error={errors.image}
            clearError={clearErrors}
          />
        </div>

        {/* <UploadImage
              initialImageUrls={initialImageUrls}
              singleUpload
              setValue={setValue}
              error={errors?.image}
              clearError={clearErrors}
              required
              name="image"
            /> */}
        <InputField
          name={"Title"}
          register={register("title", {
            required: "title is required",
            maxLength: {
              value: 256,
              message: "title cannot exceed 256 characters",
            },
          })}
          required
          placeholder={"Enter Title"}
          inputClassName="h-9"
          error={errors.title}
        />
        <InputField
          name={"Country"}
          register={register("country", {
            required: "Country is required",
            maxLength: {
              value: 256,
              message: "country cannot exceed 256 characters",
            },
          })}
          required
          placeholder={"Enter country"}
          inputClassName="h-9"
          error={errors.country}
        />
        <InputField
          name={"State Name"}
          register={register("state", {
            required: "state is required",
            maxLength: {
              value: 256,
              message: "state cannot exceed 256 characters",
            },
          })}
          required
          placeholder={"Enter state"}
          inputClassName="h-9"
          error={errors.state}
        />

        <div className="flex lg:gap-0 gap-2 justify-between mb-3">
          <div>
            <label
              htmlFor="date"
              className="block font-medium text-gray-600 text-sm mb-[1px]"
            >
              Start Date<span className="text-red-500"> *</span>
            </label>
            <Controller
              name="start_time"
              control={control}
              defaultValue={null}
              rules={{
                required: "start time is required",
              }}
              render={({ field }) => (
                <DatePicker
                  placeholderText="Select date"
                  onChange={(date) => {
                    field.onChange(date);
                    setendDate(date);
                  }}
                  showTimeSelect
                  minDate={today}
                  timeIntervals={10}
                  timeFormat="HH:mm"
                  selected={field.value ? new Date(field.value) : null}
                  dateFormat="yyyy-MM-dd HH:mm:ss"
                  className="w-full px-3 text-sm py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              )}
            />

            <>
              {errors.start_time?.message && (
                <p className="text-xs mx-1 text-red-500">
                  {errors.start_time.message.toString()}
                </p>
              )}
            </>
          </div>
          <div>
            <label
              htmlFor="date"
              className="block font-medium text-gray-600 text-sm mb-[1px]"
            >
              End Time<span className="text-red-500"> *</span>
            </label>
            <Controller
              name="end_time"
              control={control}
              defaultValue={null}
              rules={{
                required: "end time is required",
              }}
              render={({ field }) => (
                <DatePicker
                  placeholderText="Select date"
                  onChange={(date) => field.onChange(date)}
                  showTimeSelect
                  minDate={endDate}
                  timeIntervals={10}
                  selected={field.value ? new Date(field.value) : null}
                  timeFormat="HH:mm"
                  dateFormat="yyyy-MM-dd HH:mm:ss"
                  className="w-full text-sm px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              )}
            />

            <>
              {errors.end_time?.message && (
                <p className="text-xs mx-1 text-red-500">
                  {errors.end_time.message.toString()}
                </p>
              )}
            </>
          </div>
        </div>
        {prop.showModal !== "add" && (
          <div>
            <p className="font-medium text-gray-600 text-sm my-[2px]">Status</p>
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                // defaultChecked={item.is_active}
                // onChange={(e) => props.toggleWarn(e,item,'edit')}
                {...register("is_active")}
              />
              <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-500 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[1px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            </label>
          </div>
        )}
        <InputField
          name={"Description"}
          placeholder={"Enter Category"}
          register={{
            ...register("description", {
              required: "Community event description is required",
              maxLength: {
                value: 2000,
                message: "description limit cannot exceed 2000 characters",
              },
            }),
          }}
          required
          error={errors.description}
          useFor="textarea"
        />
        <InputField
          name={"Address"}
          placeholder={"Enter Address"}
          register={{
            ...register("address", {
              required: "address is required",
              maxLength: {
                value: 2000,
                message: "address limit cannot exceed 2000 characters",
              },
            }),
          }}
          required
          error={errors.address}
          useFor="textarea"
        />
      </FormPopup>
    </>
  );
};

export default Form;
