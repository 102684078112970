import React from 'react'
interface IPageLayout{
    children:React.ReactNode
}
const PageLayout:React.FC<IPageLayout> = (prop) => {
  return (
    <div className="w-full h-full  bg-white rounded-md overflow-hidden">
        {prop.children}
    </div>
  )
}

export default PageLayout