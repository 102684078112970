/* eslint-disable */
import { useEffect, useState } from "react";
import List from "./list";
import TableLayout from "../../../components/TableLayout";
import WarnPopup from "../../../components/popup/WarnPopup";
import { useNavigate } from "react-router-dom";
import { ICategoryTypes, IProductAddTypes, IToggleWarnModalTypes } from "../../../../utils/types";
import {
  deleteProductContaint,
  editStatusContaint,
  initialPage,
} from "../../../../utils/staticdata";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useWebSocket } from "../../../../api/websocket/WebSocketContext";
import {
  delete_product,
  edit_product,
  product_lists,
} from "../../../../api/websocket/payload";
import { setProductlist } from "../../../../../redux/actions/action";

const Product = () => {
  const navigate = useNavigate();

  const { send, receivedMessage, isConnect } = useWebSocket();
  const dispatch = useDispatch();
  const [showWarn, setShowWarn] = useState<IToggleWarnModalTypes>("hide");
  const [id, setid] = useState("");
  const [EditStatus, setEditStatus] = useState<IProductAddTypes>();

  useEffect(() => {
    if (isConnect) {
      if (receivedMessage) {
        if (receivedMessage.url === product_lists.url) {
          if (receivedMessage?.request?.limit !== 5000) {
            if (receivedMessage?.response?.status === 200)
              dispatch(setProductlist(receivedMessage.response.data));
            if (receivedMessage?.response?.status === 400)
              dispatch(setProductlist(initialPage));
          }
        }

        if (receivedMessage.url === edit_product.url) {
          if (receivedMessage?.response?.status === 200) {
            send(product_lists);
            toast.success(receivedMessage.response.msg);
          }
          setShowWarn("hide");
        }
        if (receivedMessage.url === delete_product.url) {
          if (receivedMessage?.response?.status === 200) {
            toast.success(receivedMessage.response.msg);
            send(product_lists);
          }
          setShowWarn("hide");
        }
      }
    }
  }, [receivedMessage]);

  useEffect(() => {
    if (isConnect) {
      const message = product_lists;
      send(message);
    }
  }, [isConnect]);

  useEffect(() => {
    return () => {
      product_lists.request = {
        filter: "all",
        limit: 10,
        page: 1,
        category_id: "",
        variant_id: "",
        search: "",
      };
    };
  }, []);

  const toggleWarn = (e: any, item: any, type: string) => {
    if (type === "delete") {
      setid(item);
      setShowWarn("delete");
    }
    if (type === "hide") {
      setShowWarn("hide");
    }

    if (type === "edit") {
      const Data: any = {
        product_id: item?.id,
        is_active: e.target.checked ? "1" : "0",
        name: item?.name,
        short_description: item?.short_description,
        description: item?.description,
        weight: item?.weight,
        dimension: {
          length: item?.dimension?.length,
          width: item?.dimension?.width,
          height: item?.dimension?.height,
        },
        purchase_note: item?.purchase_note,
        sale_price: Number(item?.sale_price) * 100,
        regular_price: Number(item?.regular_price) * 100,
        ingredients: item?.ingredients,
        image: item?.image,
        tax: item?.tax,
        in_stock: item?.in_stock,
        has_shipping: item?.has_shipping,
        has_subscription: item?.has_subscription,
        nutrition_facts: item?.nutrition_facts,
        exclude_from_pack_kit:item?.exclude_from_pack_kit
        ,

      };

      setEditStatus(Data);
      // setEditData({ ...EditStatus, request: { is_active: e.target.checked } });
      setShowWarn("edit");
    }
  };

  const editProduct = () => {
    const payload = {
      ...edit_product,
      request: {
        ...EditStatus,
        sale_price: Number(EditStatus?.sale_price) / 100,
        regular_price: Number(EditStatus?.regular_price) / 100,
      },
    };

    send(payload);
  };

  const deleteProduct = () => {
    if (isConnect) {
      const message = { ...delete_product, request: { product_id: id } };
      send(message);
    }
  };

  return (
    <TableLayout
      isAdd
      isFilter
      payload={product_lists}
      handleOpen={() => navigate("/product-management/product/add-product")}
      title={"Product"}
    >
      <List toggleWarn={toggleWarn} />
      <WarnPopup
        onSubmit={showWarn === "delete" ? deleteProduct : editProduct}
        containt={
          showWarn === "delete" ? deleteProductContaint : editStatusContaint
        }
        showModal={showWarn}
        toggleModal={toggleWarn}
      />
    </TableLayout>
  );
};

export default Product;
