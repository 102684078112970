import React from 'react'
import ListComp from '../../../components/ListComp'
import { CiEdit } from 'react-icons/ci'
import { usePosterReducers } from '../../../../../redux/getdata/usePostReducer';
import { dateFormat } from '../../../../utils/common';
import { AiOutlineDelete } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { page_lists } from '../../../../api/websocket/payload';
interface IList {
  
  toggleWarn: Function;
 
  
}
const List: React.FunctionComponent<IList> = (props)=> {
  const { pages_list } = usePosterReducers()
  const navigate = useNavigate()
  return (
    <ListComp payLoad={page_lists} pages={pages_list?.pagination}  header={['title','Type','Created At','Active','Actions']}>
      {pages_list.data?.length !== 0 &&
          pages_list.data?.map((item, index) => (
            
              <tr key={index}  className="border-b  text-sm rounded-md  ">
                
                <td>{item.title}</td>

                <td>{item.type}</td>
                <td>{dateFormat(item.created_at)}</td>
              
                <td className="px-4  text-sm py-2">
                  <label className="inline-flex items-center cursor-pointer ">
                    <input
                      type="checkbox"
                      className="sr-only peer "
                      checked={item.is_active}
                      onClick={(e) => props.toggleWarn(e,item,'edit')}
                      onChange={()=>{}}
                     
                    />
                    <div className=" relative w-9 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-500 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[1px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </td>
                <td>
                  <div className="flex justify-center items-center gap-2">
                    <button
                      onClick={() => navigate(`/content-management/pages/update-page/${item.id}`)}
                      className="p-2 text-base text-black rounded-md"
                    >
                      <CiEdit />
                    </button>
                    <button
                      onClick={() => props.toggleWarn("",item.id,'delete')}
                      className="p-2  text-base text-black rounded-md"
                    >
                      <AiOutlineDelete />
                    </button>
                  </div>
                </td>
              </tr>
            
          ))}
    </ListComp >
  )
}

export default List