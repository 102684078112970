/* eslint-disable */
import { useEffect, useState } from "react";
import List from "./list";
import Form from "./form";
import TableLayout from "../../../components/TableLayout";
import WarnPopup from "../../../components/popup/WarnPopup";
import {
  IAddRetailStoreTypes,
  IRetailStoreTypes,
  IToggleModalTypes,
  IToggleWarnModalTypes,
} from "../../../../utils/types";
import { useWebSocket } from "../../../../api/websocket/WebSocketContext";
import {
  deleteRetailContaint,
  editStatusContaint,
  initialPage,
} from "../../../../utils/staticdata";
import {
  add_retail_store,
  delete_retail_store,
  edit_retail_store,
  retail_lists,
} from "../../../../api/websocket/payload";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setRetailStorelist } from "../../../../../redux/actions/action";

const RetailStore = () => {
  const { send, receivedMessage, isConnect } = useWebSocket();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState<IToggleModalTypes>("hide");
  const [showWarn, setShowWarn] = useState<IToggleWarnModalTypes>("hide");
  const [editData, setEditData] = useState<any>();
  const [id, setid] = useState("");
  const [EditStatus, setEditStatus] = useState<IAddRetailStoreTypes>();
  useEffect(() => {
    if (isConnect) {
      if (receivedMessage) {
        if (receivedMessage?.url === "store_listing") {
          if (receivedMessage?.response?.status === 200)
            dispatch(setRetailStorelist(receivedMessage?.response?.data));
          if (receivedMessage?.response?.status === 400)
            dispatch(setRetailStorelist(initialPage));
        }
        if (receivedMessage?.url === add_retail_store.url) {
          if (receivedMessage?.response?.status === 200) {
           send(retail_lists)
            toast.success(receivedMessage?.response?.msg);
          }
          toggleModal("hide");
        }
        if (receivedMessage?.url === edit_retail_store.url) {
          if (receivedMessage?.response?.status === 200) {
           send(retail_lists)
            toast.success(receivedMessage?.response?.msg);
          }
          toggleModal("hide");
          setShowWarn("hide");
        }
        if (receivedMessage.url === delete_retail_store.url) {
          if (receivedMessage?.response?.status === 200) {
           send(retail_lists)
            toast.success(receivedMessage?.response?.msg);
          }
          setShowWarn("hide");
        }
      }
    }
  }, [receivedMessage]);

  useEffect(() => {
    if (isConnect) {
      const message = retail_lists;
      send(message);
    }
  }, [isConnect]);

  useEffect(() => {
    return () => {
      retail_lists.request = {
        filter: "all",
        limit: 10,
        page: 1,
        search: "",
      };
    };
  }, []);

  const toggleWarn = (e: any, item: any, type: string) => {
    if (type === "delete") {
      setid(item);
      setShowWarn("delete");
    }
    if (type === "hide") {
      setShowWarn("hide");
    }

    if (type === "edit") {
      const Data: any = {
        store_id: item.id,
        city: item.city,
        state: item.state,
        postcode: item.postcode,
        address: item.address,
        site_url: item.site_url,
        address_url: item.address_url,
        is_active: e.target.checked ? "1" : "0",
      };
      setEditStatus(Data);
      setShowWarn("edit");
    }
  };

  const deleteRetailStore = () => {
    if (isConnect) {
      const message = { ...delete_retail_store, request: { store_id: id } };
      send(message);
    }
  };

  const editReatailStore = () => {
    const payload = { ...edit_retail_store, request: EditStatus };
    send(payload);
  };

  const toggleModal = (state: IToggleModalTypes) => {
    setShowModal(state);
  };

  const onEdit = (item: IRetailStoreTypes, type: IToggleModalTypes) => {
    toggleModal(type);
    setEditData(item);
  };

  return (
    <TableLayout
      isFilter
      isAdd
      title={"Retail Store"}
      payload={retail_lists}
      handleOpen={() => toggleModal("add")}
    >
      <List toggleWarn={toggleWarn} edit={toggleModal} onEdit={onEdit} />

      <Form
        isEditable
        showModal={showModal}
        toggleModal={toggleModal}
        editData={editData}
      />
      <WarnPopup
        onSubmit={showWarn === "delete" ? deleteRetailStore : editReatailStore}
        containt={
          showWarn === "delete" ? deleteRetailContaint : editStatusContaint
        }
        showModal={showWarn}
        toggleModal={toggleWarn}
      />
    </TableLayout>
  );
};

export default RetailStore;
