import moment from "moment";

const CommonDateTime = (dates) => {
    const formatString = "MMMM Do YYYY";
    const DateFormatSplit = dates?.split("T");
    const DateFormat = moment(DateFormatSplit[0]).format(formatString);
    const TimeFormat = DateFormatSplit[1]?.split("Z")[0];
    
    return  DateFormat + ", " + TimeFormat;
}

export default CommonDateTime

