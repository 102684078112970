type IButtonTypes={
    label:string,
    className?:string,
    onCLick?:any,
    type?:"submit" | "reset" | "button" | undefined,
    icon?:any
}
const Button = (prop:IButtonTypes) => {
  return (
    <button type={`${prop.type?prop.type:'button'}`} className={`p-2 px-3 text-sm rounded-md text-white font-medium flex gap-1 ${prop.className} transition-colors duration-300 hover:darken-on-hover hover:brightness-90`} onClick={prop.onCLick} >{prop.icon&&<prop.icon style={{height:'20px'}}/>}{prop.label}</button>
  )
}

export default Button