/* eslint-disable */
import { useEffect, useState } from "react";
import List from "./list";
import Form from "./form";
import TableLayout from "../../../components/TableLayout";
import WarnPopup from "../../../components/popup/WarnPopup";
import {
  ICategoryTypes,
  IToggleModalTypes,
  IToggleWarnModalTypes,
} from "../../../../utils/types";
import { useWebSocket } from "../../../../api/websocket/WebSocketContext";
import {
  deleteContaint,
  editStatusContaint,
  initialPage,
} from "../../../../utils/staticdata";
import {
  category_lists,
  delete_category,
  edit_category,
} from "../../../../api/websocket/payload";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setCategorylist } from "../../../../../redux/actions/action";

const Category = () => {
  const { send, receivedMessage, isConnect } = useWebSocket();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState<IToggleModalTypes>("hide");
  const [showWarn, setShowWarn] = useState<IToggleWarnModalTypes>("hide");
  const [editData, setEditData] = useState<any>();
  const [id, setid] = useState("");
  const [EditStatus, setEditStatus] = useState<ICategoryTypes>();
  useEffect(() => {
    if (isConnect) {
      if (receivedMessage) {
        if (receivedMessage?.url === "category_listing") {
          if (receivedMessage?.response?.status === 200) {
            dispatch(setCategorylist(receivedMessage?.response?.data));
          }
          if (receivedMessage?.response?.status === 400)
            dispatch(setCategorylist(initialPage));
        }
        if (receivedMessage?.url === "category_add") {
          if (receivedMessage?.response?.status === 200) {
            send(category_lists);
            toast.success(receivedMessage?.response?.msg);
          }
          toggleModal("hide");
        }
        if (receivedMessage?.url === "category_update") {
          if (receivedMessage?.response?.status === 200) {
            send(category_lists);
            toast.success(receivedMessage?.response?.msg);
          }
          toggleModal("hide");
          setShowWarn("hide");
        }
        if (receivedMessage?.url === "category_delete") {
          if (receivedMessage?.response?.status === 200) {
            send(category_lists);
            toast.success(receivedMessage?.response?.msg);
          }
          setShowWarn("hide");
        }
      }
    }
  }, [receivedMessage]);

  useEffect(() => {
    if (isConnect) {
      const message = category_lists;
      send(message);
    }
  }, [isConnect]);

  useEffect(() => {
    return () => {
      category_lists.request = {
        filter: "all",
        limit: 10,
        page: 1,
        search: "",
      };
    };
  }, []);

  const toggleWarn = (e: any, item: any, type: string) => {
    if (type === "delete") {
      setid(item);
      setShowWarn("delete");
    }
    if (type === "hide") {
      setShowWarn("hide");
    }

    if (type === "edit") {
      const Data: any = {
        category_id: item?.id,
        is_active: e.target.checked ? "1" : "0",
        name: item?.name,
        description: item?.description,
      };

      setEditStatus(Data);
      setEditData({ ...EditStatus, request: { is_active: e.target.checked } });
      setShowWarn("edit");
    }
  };

  const deleteCategory = () => {
    if (isConnect) {
      const message = { ...delete_category, request: { category_id: id } };
      send(message);
    }
  };

  const editCategory = () => {
    const payload = { ...edit_category, request: EditStatus };
    send(payload);
  };

  const toggleModal = (state: IToggleModalTypes) => {
    setShowModal(state);
  };

  const onEdit = (item: ICategoryTypes, type: IToggleModalTypes) => {
    toggleModal(type);
    setEditData(item);
  };

  return (
    <TableLayout
      isFilter
      title={"Category"}
      isAdd
      payload={category_lists}
      handleOpen={() => toggleModal("add")}
    >
      <List toggleWarn={toggleWarn} edit={toggleModal} onEdit={onEdit} />

      <Form
        isEditable
        showModal={showModal}
        toggleModal={toggleModal}
        editData={editData}
      />
      <WarnPopup
        onSubmit={showWarn === "delete" ? deleteCategory : editCategory}
        containt={showWarn === "delete" ? deleteContaint : editStatusContaint}
        showModal={showWarn}
        toggleModal={toggleWarn}
      />
    </TableLayout>
  );
};

export default Category;
