import InputField from "../../../components/input/InputField";
import { useForm } from "react-hook-form";
import {
  IAddRetailStoreTypes,
  IToggleModalTypes,
} from "../../../../utils/types";
import FormPopup from "../../../components/popup/FormPopup";
import { useWebSocket } from "../../../../api/websocket/WebSocketContext";
import {
  add_retail_store,
  edit_retail_store,
} from "../../../../api/websocket/payload";
import { useEffect } from "react";

interface IForm {
  showModal: string;
  toggleModal: (state: IToggleModalTypes) => void;
  editData: IAddRetailStoreTypes;
  isEditable: boolean;
}

const Form = (prop: IForm) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IAddRetailStoreTypes>();
  const { send } = useWebSocket();
  const formContaintCat = {
    title: `${prop.showModal === "edit" ? "Edit" : "Add"} Retail Store`,
    submit: "Submit",
    close: "Cancel",
  };

  useEffect(() => {
    if (prop.showModal === "edit") {
      reset(prop.editData); // Resets the form values to prop.editData
    } else {
      reset({
        address: "",
        address_url: "",
        city: "",
        landmark: "",
        postcode: "",
        site_url: "",
        state: "",
      }); // Resets the form values to prop.editData
    }
  }, [prop.showModal, reset, prop.editData]);

  const onSubmit = (data: IAddRetailStoreTypes) => {
    if (prop.showModal === "add") {
      const payload = { ...add_retail_store, request: data };
      send(payload);
    }
    if (prop.showModal === "edit") {
      const Data = {
        store_id: prop.editData.id,
        city: data.city,
        state: data.state,
        postcode: data.postcode,
        address: data.address,
        site_url: data.site_url,
        address_url: data.address_url,
        is_active: data.is_active ? "1" : "0",
      };
      const payload = { ...edit_retail_store, request: Data };

      send(payload);
    }
  };

  return (
    <>
      <FormPopup
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        containt={formContaintCat}
        showModal={prop.showModal}
        toggleModal={prop.toggleModal}
      >
        {prop.showModal === "add" && (
          <InputField
            name={"Landmark"}
            placeholder={"Enter Landmark"}
            register={{
              ...register("landmark", {
                required: "landmark is required",
                maxLength: {
                  value: 256,
                  message: "landmark cannot exceed 256 characters",
                },
              }),
            }}
            required
            inputClassName="h-9"
            error={errors.landmark}
          />
        )}

        <InputField
          name={"City"}
          placeholder={"Enter City"}
          register={{
            ...register("city", {
              required: " city is required",
              maxLength: {
                value: 256,
                message: "city cannot exceed 256 characters",
              },
            }),
          }}
          required
          inputClassName="h-9"
          error={errors.city}
        />
        <InputField
          name={"State"}
          placeholder={"Enter State"}
          register={{
            ...register("state", {
              required: "state is required",
              maxLength: {
                value: 256,
                message: "state cannot exceed 256 characters",
              },
            }),
          }}
          required
          inputClassName="h-9"
          error={errors.state}
        />
        <InputField
          name={"Postcode"}
          placeholder={"Enter Postcode"}
          register={{
            ...register("postcode", {
              required: "postcode is required",
              maxLength: {
                value: 6,
                message: "Postcode must be 5-6 digits",
              },
              minLength: {
                value: 5,
                message: "Postcode must be 5-6 digits",
              },
              pattern: {
                value: /^[0-9]+$/,
                message: "postcode must be a number",
              },
            }),
          }}
          required
          inputClassName="h-9"
          error={errors.postcode}
        />
        <InputField
          name={"Address"}
          placeholder={"Enter Address"}
          register={{
            ...register("address", {
              required: "address is required",
              maxLength: {
                value: 2000,
                message: "address cannot exceed 2000 characters",
              },
            }),
          }}
          required
          useFor="textarea"
          error={errors.address}
        />

        {prop.showModal !== "add" && (
          <div>
            <p className="font-medium text-gray-600 text-sm my-[2px]">Status</p>
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                // defaultChecked={item.is_active}
                // onChange={(e) => props.toggleWarn(e,item,'edit')}
                {...register("is_active")}
              />
              <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-500 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[1px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            </label>
          </div>
        )}
        <InputField
          name={"Site URL"}
          placeholder={"Enter Site URL"}
          register={{
            ...register("site_url", {
              required: "site url is required",
              maxLength: {
                value: 2000,
                message: "site url cannot exceed 2000 characters",
              },
            }),
          }}
          required
          inputClassName="h-9"
          error={errors.site_url}
        />
        <InputField
          name={"Address URL"}
          placeholder={"Enter Address URL"}
          register={{
            ...register("address_url", {
              required: "address url is required",
              maxLength: {
                value: 2000,
                message: "address url cannot exceed 2000 characters",
              },
            }),
          }}
          required
          inputClassName="h-9"
          error={errors.state}
        />
      </FormPopup>
    </>
  );
};

export default Form;
