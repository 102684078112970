import applogo from "../../../asset/images/applogo.png";
import Form from "./form";
// import Form from "./form";

const Login = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-[#f2f8fb] ">
      <div>
        <div className="text-center">
          <div className="flex  justify-center">
            <div
              className="bg-cover bg-center h-32 w-32 "
              style={{ backgroundImage: `url(${applogo})` }}
            ></div>
          </div>
          <div className="text-3xl my-6 text-[#2737FF] font-medium">
            Daddy's Dough
          </div>
        </div>
        <Form />
      </div>
    </div>
  );
};

export default Login;
