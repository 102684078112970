/* eslint-disable */
import { useEffect, useState } from "react";
import List from "./list";
import Form from "./form";
import TableLayout from "../../../components/TableLayout";
import WarnPopup from "../../../components/popup/WarnPopup";
import {
  IAboutUsType,
  ICategoryTypes,
  IToggleModalTypes,
  IToggleWarnModalTypes,
} from "../../../../utils/types";
import { useWebSocket } from "../../../../api/websocket/WebSocketContext";
import {
  deleteAboutusContaint,
  editStatusContaint,
  initialPage,
} from "../../../../utils/staticdata";
import {
  about_us_lists,
  delete_about_us,
  edit_about_us,
} from "../../../../api/websocket/payload";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setAboutuslist } from "../../../../../redux/actions/action";
import { useNavigate } from "react-router-dom";

const AboutUs = () => {
  const navigate = useNavigate();
  const { send, receivedMessage, isConnect } = useWebSocket();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState<IToggleModalTypes>("hide");
  const [showWarn, setShowWarn] = useState<IToggleWarnModalTypes>("hide");
  const [editData, setEditData] = useState<any>();
  const [id, setid] = useState("");
  const [EditStatus, setEditStatus] = useState<ICategoryTypes>();
  useEffect(() => {
    if (isConnect) {
      if (receivedMessage) {
        if (receivedMessage.url === "story_listing") {
          if (receivedMessage?.response?.status === 200)
            dispatch(setAboutuslist(receivedMessage.response.data));
          if (receivedMessage?.response?.status === 400)
            dispatch(setAboutuslist(initialPage));
        }

        if (receivedMessage.url === "story_delete") {
          if (receivedMessage?.response?.status === 200) {
            send(about_us_lists)
            toast.success(receivedMessage.response.msg);
          }
          setShowWarn("hide");
        }

        if (receivedMessage.url === edit_about_us.url) {
          if (receivedMessage?.response?.status === 200) {
            send(about_us_lists)
            toast.success(receivedMessage.response.msg);
          }
          navigate("/content-management/about-us");
          setShowWarn("hide");
        }
      }
    }
  }, [receivedMessage]);

  useEffect(() => {
    if (isConnect && receivedMessage?.url !== about_us_lists.url) {
      const message = about_us_lists;
      send(message);
    }
  }, [isConnect]);

  useEffect(() => {
    return () => {
      about_us_lists.request = {
        filter: "all",
        limit: 10,
        page: 1,
        search: "",
      };
    };
  }, []);

  const toggleWarn = (e: any, item: any, type: string) => {


    if (type === "delete") {
      setid(item);
      setShowWarn("delete");
    }
    if (type === "hide") {
      setShowWarn("hide");
    }

    if (type === "edit") {
      const Data: any = {
        story_id: item?.id,
        is_active: e.target.checked ? "1" : "0",
        title: item?.title,
        description1: item?.description1,
        description2: item?.description2,
        images: item?.images,
      };

      setEditStatus(Data);

      setShowWarn("edit");
    }
  };
  const toggleModal = (state: IToggleModalTypes) => {
    setShowModal(state);
  };

  const onEdit = (item: IAboutUsType, type: IToggleModalTypes) => {
    toggleModal(type);
    setEditData(item);
  };
  const deleteAboutUs = () => {
    if (isConnect) {
      const message = { ...delete_about_us, request: { story_id: id } };
      send(message);
    }
  };

  const editAboutus = () => {
    const payload = { ...edit_about_us, request: EditStatus };
    send(payload);
  };

  return (
    <TableLayout
      isFilter
      isAdd
      title="About us"
      payload={about_us_lists}
      handleOpen={() => navigate("/content-management/about-us/add-aboutus")}
    >
      <List toggleWarn={toggleWarn} edit={toggleModal} onEdit={onEdit} />
      <WarnPopup
        onSubmit={showWarn === "delete" ? deleteAboutUs : editAboutus}
        containt={
          showWarn === "delete" ? deleteAboutusContaint : editStatusContaint
        }
        showModal={showWarn}
        toggleModal={toggleWarn}
      />
    </TableLayout>
  );
};

export default AboutUs;
