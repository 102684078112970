/*eslint-disable*/
import { useEffect, useState } from "react";
import TableLayout from "../../../components/TableLayout";
import WarnPopup from "../../../components/popup/WarnPopup";
import {
  IGiftCard,
  IToggleModalTypes,
  IToggleWarnModalTypes,
} from "../../../../utils/types";
import {
  add_gift_card,
  delete_gift_card,
  edit_gift_card,
  gift_card_lists,
} from "../../../../api/websocket/payload";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useWebSocket } from "../../../../api/websocket/WebSocketContext";
import { setGiftcardList } from "../../../../../redux/actions/action";
import { editStatusContaint } from "../../../../utils/staticdata";
import List from "./list";
import Form from "./form";

const GiftCard = () => {
  const deleteContaint = {
    title: "Delete Gift card",
    description: "Are you sure you want to delete this Gift card ?",
    submit: "Confirm",
    close: "Close",
  };
  const { send, receivedMessage, isConnect } = useWebSocket();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState<IToggleModalTypes>("hide");
  const [showWarn, setShowWarn] = useState<IToggleWarnModalTypes>("hide");
  const [EditStatus, setEditStatus] = useState<IGiftCard>();
  const [id, setid] = useState("");
  const [editData, setEditData] = useState<any>();
  const toggleWarn = (e: any, item: any, type: string) => {
    if (type === "delete") {
      setid(item);
      setShowWarn("delete");
    }
    if (type === "hide") {
      setShowWarn("hide");
    }

    if (type === "edit") {
      const Data: any = {
        card_id: item?.id,
        amount: item?.amount,
        is_active: e.target.checked ? "1" : "0",
      };

      setEditStatus(Data);
      setShowWarn("edit");
    }
  };

  const deleteGiftcard = () => {
    if (isConnect) {
      const message = { ...delete_gift_card, request: { card_id: id } };
      send(message);
    }
  };

  const editGiftcard = () => {
    const payload = { ...edit_gift_card, request: EditStatus };
    send(payload);
  };
  const toggleModal = (state: IToggleModalTypes) => {
    setShowModal(state);
  };

  const onEdit = (item: IGiftCard, type: IToggleModalTypes) => {
    toggleModal(type);
    setEditData(item);
  };
  useEffect(() => {
    if (isConnect) {
      if (receivedMessage?.url === gift_card_lists.url) {
        dispatch(setGiftcardList(receivedMessage?.response?.data));
      }

      if (receivedMessage?.url === add_gift_card.url) {
        if (receivedMessage?.response?.status === 200) {
          send(gift_card_lists)
          toast.success(receivedMessage?.response?.msg);
        }
        toggleModal("hide");
      }
      if (receivedMessage?.url === edit_gift_card.url) {
        if (receivedMessage?.response?.status === 200) {
          send(gift_card_lists)
          toast.success(receivedMessage?.response?.msg);
        }
        toggleModal("hide");
        setShowWarn("hide");
      }
      if (receivedMessage?.url === delete_gift_card.url) {
        if (receivedMessage?.response?.status === 200) {
          send(gift_card_lists)
          toast.success(receivedMessage?.response?.msg);
        }
        setShowWarn("hide");
      }
    }
  }, [receivedMessage]);

  useEffect(() => {
    if (isConnect) {
      const message = gift_card_lists;
      send(message);
    }
  }, [isConnect]);

  useEffect(() => {
    return () => {
      gift_card_lists.request = {
        filter: "all",
        limit: 10,
        page: 1,
      };
    };
  }, []);
  return (
    <TableLayout
      title={"Gift Card"}
      isFilter
      isAdd
      payload={gift_card_lists}
      handleOpen={() => toggleModal("add")}
    >
      <List onEdit={onEdit} toggleWarn={toggleWarn} edit={toggleModal} />

      <Form
        showModal={showModal}
        isEditable
        toggleModal={toggleModal}
        editData={editData}
      />
      <WarnPopup
        onSubmit={showWarn === "delete" ? deleteGiftcard : editGiftcard}
        containt={showWarn === "delete" ? deleteContaint : editStatusContaint}
        showModal={showWarn}
        toggleModal={toggleWarn}
      />
    </TableLayout>
  );
};

export default GiftCard;
