import React from "react";
import CoutCard from "./Card/CoutCard";
import { PaymentsAmount } from "../../utils/types";
import { useNavigate } from "react-router-dom";

interface IDashboardCardType {
  title: string;
  data?: PaymentsAmount;
  link?: string;
}
const DashBoardCard: React.FC<IDashboardCardType> = (prop) => {
  const navigate=useNavigate()
  return (
    <div className="bg-secondary text-sm py-3 px-2 w-full rounded-md lg:my-0 my-3 cursor-pointer" onClick={()=>prop.link&&navigate(prop.link)}>
      <div className="flex font-semibold justify-between text-gray-500">
        <p className="py-1">{prop.title}</p>
        <p className="py-1">{prop.data?.total}</p>
      </div>
      <div className="flex  justify-evenly items-center p-2 bg-white rounded-md gap-4 ">
        <CoutCard
          className="bg-gradient-to-r from-indigo-500 to-cyan-500"
          title="Today"
          count={prop.data?.count_today}
        />
        <CoutCard
          className="bg-gradient-to-bl from-pink-500 to-orange-500"
          title="This Week"
          count={prop.data?.count_past_week}
        />
        <CoutCard
          className="bg-gradient-to-t from-purple-500 to-yellow-500"
          title="This Month"
          count={prop.data?.count_past_month}
        />
      </div>
    </div>
  );
};

export default DashBoardCard;
