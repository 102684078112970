import { AiOutlineDelete } from "react-icons/ai";
import React from "react";
import ListComp from "../../../components/ListComp";
import { IoEyeOutline } from "react-icons/io5";
import { CiEdit } from "react-icons/ci";
import { usePosterReducers } from "../../../../../redux/getdata/usePostReducer";
import { dateFormat } from "../../../../utils/common";
import { useNavigate } from "react-router-dom";
import { product_lists } from "../../../../api/websocket/payload";

interface IList {
  toggleWarn: Function;
}

const List: React.FunctionComponent<IList> = (props) => {
  const header = [
    "Seq. No",
    "Image",
    "Name",
    "Product Type",
    "Regular Price",
    "Sale Price",
    "Created At",
    "Status",
    "Actions",
  ];
  const { product_list } = usePosterReducers();
  const navigate = useNavigate();
  

  return (
    <ListComp header={header}  pages={product_list?.pagination} payLoad={product_lists}>
      {product_list?.data?.length !== 0 &&
        product_list?.data?.map((item, index) => (
          <tr key={index} className="border-b text-sm rounded-md  ">
            <td>{item.sequence_no}</td>
            <td className="p-3  flex justify-center">
              <img className="h-20 w-24" src={item.image} alt="" />
            </td>
            <td>{item.name}</td>
            <td>{item.product_type.charAt(0).toUpperCase()+item.product_type.slice(1)}</td>
            <td>{item.regular_price?Number(item.regular_price)/100:'-'}</td>
            <td>{item.sale_price?Number(item.sale_price)/100:'-'}</td>
            <td>{dateFormat(item.created_at)}</td>

            <td className="px-4  text-sm py-2">
              <label className="inline-flex items-center cursor-pointer ">
                <input
                  type="checkbox"
                  className="sr-only peer "
                  checked={item.is_active}
                  onChange={()=>{}}
                  onClick={(e)=>props.toggleWarn(e, item, "edit")}
                />
                <div className=" relative w-9 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-500 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[1px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
            </td>
            <td>
              <div className="flex justify-center items-center ">
                <button
                  onClick={() =>
                    navigate("/product-management/product/"+item.id)
                  }
                  className="p-2   text-black rounded-md"
                >
                  <IoEyeOutline />
                </button>
                <button
                  onClick={() =>
                    navigate(
                      "/product-management/product/edit-product/" + item.id
                    )
                  }
                  className="p-2 text-base text-black rounded-md"
                >
                  <CiEdit />
                </button>
                <button
                  onClick={() => props.toggleWarn("", item.id, "delete")}
                  className="p-2  text-base text-black rounded-md"
                >
                  <AiOutlineDelete />
                </button>
              </div>
            </td>
          </tr>
        ))}
    </ListComp>
  );
};

export default List;
