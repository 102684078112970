import React from "react";
import { MdNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";

interface PaginationProps {
  currentPage: number;
  recordLimit: number;
  totalRecords: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  recordLimit,
  totalRecords,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalRecords / recordLimit);

  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handlePageSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const selectedPage = Number(event.target.value);
    onPageChange(selectedPage);
  };

  const pageOptions = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <div className="flex justify-center items-center mt-4">
      <button
        onClick={handlePrevious}
        disabled={currentPage === 1}
        className="px-4 py-2 mx-2 bg-primary hover:brightness-75 text-white rounded disabled:bg-gray-300"
      >
        <GrFormPrevious />
      </button>
      <span className="mx-2">
        Page{" "}
        <select
          value={currentPage}
          onChange={handlePageSelect}
          className="mx-2 px-2 py-1 border rounded"
        >
          {pageOptions?.map((page) => (
            <option key={page} value={page}>
              {page}
            </option>
          ))}
        </select>{" "}
        of {isNaN(totalPages) ? 1 : totalPages}
      </span>

      <button
        onClick={handleNext}
        disabled={currentPage === totalPages}
        className="px-4 py-2 mx-2 bg-primary hover:brightness-75 text-white rounded disabled:bg-gray-300"
      >
        <MdNavigateNext />
      </button>
    </div>
  );
};

export default Pagination;
