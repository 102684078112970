/* eslint-disable */
import React, { useEffect, useState } from "react";
import { IPaginationTypes } from "../../utils/types";
import Pagination from "./Pagination";
import { useWebSocket } from "../../api/websocket/WebSocketContext";

interface IListTypes {
  children: React.ReactNode;
  header: any[];
  pages?: IPaginationTypes;
  payLoad?: any;
}

const ListComp: React.FC<IListTypes> = (props) => {
  const { send } = useWebSocket();
  const [currentPage, setCurrentPage] = useState(1);
  const currentFunction = (page: number) => {
    setCurrentPage(page);
    props.payLoad.request.page = page;
    send(props.payLoad);
  };



  return (
    <>
      <div
        id="list-scroll"
        className="overflow-x-auto lg:h-[80%] md:h-[75%] sm:h-[80%] h-[70%] border rounded-md"
      >
        <div className="min-w-full ">
          <div className="overflow-auto  border-t-gray-200 bg-white rounded-tl-md rounded-tr-md">
            <table className="min-w-full   divide-gray-200">
              <thead className="bg-gray-50">
                <tr className="text-left">
                  {props.header?.map((item, index) => (
                    <th
                      key={index}
                      className="px-4 py-2  text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white list-group divide-y text-left divide-gray-200">
                {props.children}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className=" flex lg:justify-end justify-center  items-center">
        {props?.pages && (
          <Pagination
            currentPage={currentPage}
            onPageChange={currentFunction}
            totalRecords={Number(props?.pages?.total_records)}
            recordLimit={parseFloat(props.payLoad?.request?.limit)}
          />
        )}
      </div>
    </>
  );
};

export default ListComp;
