import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import posterReducer from "./reducers/posterReducer";
import uiReducer from "./reducers/uiReducer";
import { InitialState } from "../app/utils/types";

export interface ApplicationState {
  posterReducers: InitialState;
}

const combinedReducer = combineReducers({
  posterReducers: posterReducer,
  uiReducer:uiReducer
});

const rootReducer = combineReducers({
  combinedReducer: persistReducer({ key: "chathub-store", storage, blacklist: ["uiReducer"], }, combinedReducer),
});


export function makeStore() {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ["persist/PERSIST"], // Ignore serializability check for specific actions
        },
      })
  });

  const persistor = persistStore(store);
  return { store, persistor };
}

const { store, persistor } = makeStore();
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { store, persistor };
