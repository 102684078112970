import React from 'react'

import { CiEdit } from 'react-icons/ci'
import { AiOutlineDelete } from 'react-icons/ai'
import { usePosterReducers } from '../../../../redux/getdata/usePostReducer';
import ListComp from '../../components/ListComp';
import { dateFormat } from '../../../utils/common';
import { coupon_lists } from '../../../api/websocket/payload';

interface IList {
  edit: Function;
  toggleWarn: Function;
  onEdit:Function,
  
}
const List:React.FC<IList> = (props) => {
  const { coupon_list } = usePosterReducers();
    const header=['Name','code','discount type','description','discount_value','min. amount','usage limit','expiry date','Created At','Status','Actions']
   

    
    
  return (
    <ListComp pages={coupon_list?.pagination} payLoad={coupon_lists} header={header}>
       {coupon_list?.data?.length !== 0 &&
          coupon_list?.data?.map((item, index) => (
            
              <tr key={index}  className="border-b text-sm rounded-md  ">
                <td className="p-3">{item.name}</td>
                <td className="p-3">{item.code}</td>
                <td className="p-3">{(item.discount_type).charAt(0).toUpperCase()+(item.discount_type).slice(1)}</td>
                {/* <td className="p-3">{item.description}</td> */}
                <td>
                  {item.description === null ? "-" : (<div className="flex  items-center border p-1 mx-2 my-1 rounded-md justify-center h-full">
                    <textarea
                      id="product-name"
                      className="rounded focus:outline-none   w-full h-full text-sm resize-none leading-7"
                      value={item.description} // Join items with newline for vertical display
                      readOnly
                    />
                  </div>)}
            </td>
                <td className="p-3">{item.discount_value}</td>
                <td className="p-3">{item.min_amount}</td>
                <td className="p-3">{item.usage_limit === null ? "-" : item.usage_limit}</td>
                {/* <td className="p-3">{item.discount}</td> */}
                
                <td>{item.expiry_date === null ? "-" : dateFormat(item.expiry_date)}</td>
                <td>{dateFormat(item.created_at)}</td>
              
                <td className="px-4  text-sm py-2">
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only peer"
                      checked={item.is_active}
                      onChange={()=>{}}
                      onClick={(e) => props.toggleWarn(e,item,'edit')}
                     
                    />
                    <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-500 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[1px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </td>
                <td>
                  <div className="flex justify-center items-center gap-2">
                    <button
                      onClick={() => props.onEdit(item,'edit')}
                      className="p-2 text-base text-black rounded-md"
                    >
                      <CiEdit />
                    </button>
                    <button
                      onClick={() => props.toggleWarn("",item.id,'delete')}
                      className="p-2  text-base text-black rounded-md"
                    >
                      <AiOutlineDelete />
                    </button>
                  </div>
                </td>
              </tr>
            
          ))}
    </ListComp>
  )
}

export default List