import React, { useEffect, useRef } from "react";
import { IToggleWarnModalTypes } from "../../../utils/types";
interface IWarnPopupTypes {
  toggleModal: Function;
  onSubmit: Function;
  showModal: IToggleWarnModalTypes;
  containt?: {
    title: string;
    description: string;
    submit: string;
    close: string;
  };
}
const WarnPopup: React.FunctionComponent<IWarnPopupTypes> = (prop) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Check if the click is outside the dropdown container
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        prop.toggleModal("", "", "hide");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
  return (
    <>
      {prop.showModal !== "hide" && (
        <div
          id="default-modal"
          className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-full bg-gray-400 bg-opacity-50"
        >
          <div className="relative p-4 w-full max-w-lg ">
            <div
              ref={dropdownRef}
              className="relative  bg-white rounded-lg shadow"
            >
              <div className="flex items-center justify-between p-4  md:p-4 rounded-t">
                <div className="w-full text-center">
                  <h3 className="text-lg text-gray-700 ">
                    {prop.containt?.title}
                  </h3>
                </div>
                <button
                  onClick={() => prop.toggleModal("", "", "hide")}
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-[#2737FF] rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-[#2737FF] dark:hover:text-white"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">{prop.containt?.close}</span>
                </button>
              </div>
              <div className="mx-2">
                <p className="text-center text-sm text-gray-600">
                  {prop.containt?.description}
                </p>
              </div>
              <div className="flex items-center justify-center p-3 md:p-3 mt-2">
                <button
                  type="button"
                  onClick={(e) => {
                    prop.onSubmit(e);
              
                  }}
                  className="text-white bg-blue-700 hover:bg-blue-800  focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  {prop.containt?.submit}
                </button>
                <button
                  onClick={() => prop.toggleModal("", "", "hide")}
                  className="py-2.5 px-5 ms-3 text-sm font-medium bg-gray-300 hover:bg-gray-400 rounded-lg"
                >
                  {prop.containt?.close}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WarnPopup;
