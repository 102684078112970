/* eslint-disable */
import { useEffect } from "react";
import TableLayout from "../../../components/TableLayout";
import { useWebSocket } from "../../../../api/websocket/WebSocketContext";
import { subscription_email_lists } from "../../../../api/websocket/payload";
import { useDispatch } from "react-redux";
import { setSubscriptionEmaillist } from "../../../../../redux/actions/action";
import List from "./list";

const SubscriptionMail = () => {
  const { send, receivedMessage, isConnect } = useWebSocket();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isConnect) {
      if (receivedMessage) {
        if (receivedMessage?.url === subscription_email_lists.url) {
          dispatch(setSubscriptionEmaillist(receivedMessage?.response?.data));
        }
      }
    }

   
  }, [receivedMessage]);

  useEffect(() => {
    if (isConnect) {
      const message = subscription_email_lists
      send(message);
    }
  }, [isConnect, receivedMessage?.url]);

  useEffect(() => {
    return () => {
      subscription_email_lists.request = {
        filter: "all",
        limit: 10,
        page: 1,
        search: "",
      };
    };
  }, [])

  return (
    <TableLayout
      isAdd={false}
      title={"Subcription Email"}
      payload={subscription_email_lists}
    >
      <List />
    </TableLayout>
  );
};

export default SubscriptionMail;
