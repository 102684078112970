import { AiOutlineDelete } from "react-icons/ai";
import { IoEyeOutline } from "react-icons/io5";

import React from "react";
import ListComp from "../../../components/ListComp";
import { usePosterReducers } from "../../../../../redux/getdata/usePostReducer";
import { dateFormat } from "../../../../utils/common";
import { catering_lists } from "../../../../api/websocket/payload";
import { useNavigate } from "react-router-dom";
import CommonDateTime from "../../../../utils/CommonDateTime";

interface IList {

  toggleWarn: Function;

  
}

const List: React.FunctionComponent<IList> = (props) => {
  const { catering_list } = usePosterReducers();
const navigate=useNavigate()
  const header = [
    "Name",
    "email",
    "event date",
    "delivery date",
    "delivery method",

    "Created At",
    "Actions",
  ];

  return (
    <ListComp payLoad={catering_lists} pages={catering_list?.pagination} header={header}>
      
        {catering_list?.data?.length !== 0 &&
          catering_list?.data?.map((item, index) => {
            return(<tr key={index}  className="border-b text-sm rounded-md  ">
           
              <td className="p-3">{item.name}</td>
              <td>{item.email}</td>
              <td>{CommonDateTime(item.event_date)}</td>
              <td>{CommonDateTime(item.delivery_date)}</td>
              <td>{item.delivery_method}</td>
              <td>{dateFormat(item.created_at)}</td>
              <td>
                <div className="flex justify-center items-center gap-2">
                <button
                onClick={() =>
                  navigate("/content-management/catering/"+item.id)
                }
                className="p-2   text-black rounded-md"
              >
                <IoEyeOutline />
              </button>
                  <button
                    onClick={() => props.toggleWarn("",item.id,'delete')}
                    className="p-2  text-base text-black rounded-md"
                  >
                    <AiOutlineDelete />
                  </button>
                </div>
              </td>
            </tr>)
          })}
    
    </ListComp>
  );
};

export default List;
