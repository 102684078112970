/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { useWebSocket } from "../../api/websocket/WebSocketContext";
// import { App_url } from "../../utils/constants/static";

interface DropdownProps {
  label: string | number;
  options?: { value: string | number; label: string | number }[];
  value?: string | number | (string | number)[]; // Allow for single value or array of values
  onChange: (value: string | number | (string | number)[]) => void;
  name: string;
  onSelect?: (value: string | number | (string | number)[]) => void;
  className?: string;
  errors?: any;
  required?: boolean;
  isDynamic?: boolean;
  disabled?: boolean,
  defaultValue?: string;
  multiselect?: boolean;
  isSearch?: boolean; 
  payload?: any;
  children ?: any;
  lableName?: any;
}

const Dropdown: React.FC<DropdownProps> = ({
  errors,
  label,
  options,
  value,
  onChange,
  name,
  onSelect,
  className,
  required,
  isDynamic,
  defaultValue,
  disabled,
  isSearch,
  payload,
  lableName,
  children = undefined,
  multiselect = false, // Default to single select if not provided
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedValues, setSelectedValues] = useState<any[]>(Array.isArray(value) ? value : [value]);
  const [searchInput, setSearchInput] = useState(payload?.payload?.query || "");
  const [popupDirection, setPopupDirection] = useState<'up' | 'down'>('down');
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { send } = useWebSocket();
  const handleSelectChange = (selectedValue: string | number) => {
    setSelectedValues([selectedValue]);
    onChange(selectedValue);
    if (onSelect) {
      onSelect(selectedValue);
    }
    setShowPopup(false); // Close the popup after selecting an option
  };

  const handleCheckboxChange = (optionValue: string | number) => {
    const currentIndex = selectedValues.indexOf(optionValue);
    const newSelectedValues = [...selectedValues];

    if (currentIndex === -1) {
      newSelectedValues.push(optionValue);
    } else {
      newSelectedValues.splice(currentIndex, 1);
    }

    setSelectedValues(newSelectedValues);
    onChange(newSelectedValues);

    if (onSelect) {
      onSelect(newSelectedValues);
    }
  };

  const togglePopup = () => {
    if(!disabled) {
      setShowPopup(!showPopup);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
    if (payload) {
      payload.query = e.target.value;
      send(payload);
    }
    
  };

  const filteredOptions = isSearch
    ? options?.filter(option =>
        option?.label?.toString().toLowerCase()?.includes(searchInput?.toLowerCase())
      )
    : options;

   
    

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowPopup(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (Array.isArray(value)) {
      setSelectedValues(value);
    } else {
      setSelectedValues([value]);
    }
  }, [value]);

 
  return (
    <div className={`${className} maincustom-dropdonn`}>
      {label && (
        <label
          className="dropdown-label font-medium text-gray-600 text-sm mb-[1px]"
          htmlFor={name}
        >
          {label}
          {required && <span className="text-red-500"> *</span>}
        </label>
      )}
      <div aria-disabled={disabled} onClick={togglePopup} className={`dropdown  ${!errors?'border-[#CCD0CF]':'border-red-600'} flex items-center border p-1 text-sm py-[0.65rem] px-4 rounded-md `}>
        <div className="relative w-full">
          <button
            type="button"
            className="focus:outline-none w-full dropdown-select text-left"
            disabled={disabled}
          >
            {selectedValues.length > 0 && selectedValues[0] !== undefined ?(
              selectedValues.map((selectedValue) => (
                <span key={selectedValue} className="mr-2 ">
                  {options?.find((opt) => opt.value === selectedValue)?.label}
                </span>
              ))
            ) : (
              <span className="text-gray-400">-- Select {multiselect ? defaultValue : defaultValue} --</span>
            )}
          </button>
          {showPopup && (
            <div
              ref={dropdownRef}
              className={`absolute custom-dropdwon-content z-50  bg-white p-3 w-full shadow-md border rounded mt-1 ${popupDirection === 'up' ? 'bottom-full mb-2' : 'top-full mt-2'}`}
            >
              {isSearch && (
                <input
                  type="text"
                  value={searchInput}
                  onChange={handleSearchChange}
                  placeholder="Search..."
                  className="w-full p-1 mb-2 border rounded"
                />
              )}
              {multiselect ? (
                filteredOptions?.map((option) => (
                  <label key={option.value} className="block">
                    <input
                      type="checkbox"
                      value={option.value}
                      checked={selectedValues.includes(option.value)}
                      onChange={() => handleCheckboxChange(option.value)}
                      className="mr-2"
                    />
                    {option.label}
                  </label>
                ))
              ) : (
                <ul className="max-h-60 overflow-auto">
                  {filteredOptions?.map((option) => (
                    <li
                      key={option.value}
                      className="p-2 cursor-pointer hover:bg-gray-200"
                      onClick={() => handleSelectChange(option.value)}
                    >
                      {option.label}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
        </div>
      </div>
      {/* {required && errors && ("{errors")} */}
      {errors && lableName === "product_select" && (<div className="flex items-center">
        <p className="text-xs   mx-1 text-red-600">Product is required</p>
      </div>)}
      {required && errors && ( // Display error message if required and errors exist
        <div className="flex items-center">
        {/* <img
          className="h-[0.5rem] "
          src={App_url.image.info}
          alt=""
        /> */}
        <p className="text-xs   mx-1 text-red-600">
          {errors.message}
        </p>
      </div>
      )}
    </div>
  );
};

export default Dropdown;
