/* eslint-disable */
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import FormLayout from "../../../components/FormLayout";
import { useForm, Controller } from "react-hook-form";
import { IFooterOrderSetting,IFooterOrderSetting2 } from "../../../../utils/types";
import InputField from "../../../components/input/InputField";
import { usePosterReducers } from "../../../../../redux/getdata/usePostReducer";
import { useWebSocket } from "../../../../api/websocket/WebSocketContext";

interface IAppProps {
    setLoading: Dispatch<SetStateAction<boolean>>; 
    loading:boolean
  }
const FormforOrderPerDay : React.FunctionComponent<IAppProps> = ({setLoading,loading}) => {
  const { register, handleSubmit, reset, control, watch, formState: { errors } } = useForm<IFooterOrderSetting2>();
  const { send, receivedMessage,isConnect } = useWebSocket();
  const { shipping_dates } = usePosterReducers();


  useEffect(() => {
    if(!loading){
        reset({
          data: {
            ...shipping_dates,
            orders_per_day: shipping_dates?.data?.orders_per_day,
          }
        });
    }
  }, [receivedMessage]);

  const onSubmitDailyLimit = (data: IFooterOrderSetting2) => {
    const payload = {
        transmit: "single",
        url: "setting_order_date_update",
        request: {
         data: {
          excluded_days: {
            from: shipping_dates.data.excluded_days.from,
            to: shipping_dates?.data?.excluded_days?.to
          },
          orders_per_day: data?.data?.orders_per_day
          },
          }
        }
        send(payload);
        setLoading(true)
        console.log("payload in day form",payload)
      }
  
  return (
      <FormLayout
        handleSubmit={handleSubmit}
        onSubmit={onSubmitDailyLimit}
        containt={{ title: "Daily Delivery Limit", submit: "Save" }}
      >
        <div className="lg:flex w-full gap-2">
          <InputField
            register={register("data.orders_per_day", {
              required: "Orders per day is required",
              pattern: {
                // value: /^-?(?!0$)([1-9]\d*|0)$/,
                value: /^(?!0$)([1-9]\d*|0)$/,
                message: "Orders per day must be a number",
              },
            })}
            required
            name={"Orders Per Day"}
            placeholder={"Enter Orders Per Day"}
            inputClassName="h-9"
            className="lg:w-80 mt-[20px]"
            error={errors?.data?.orders_per_day}
          />
        </div>
      </FormLayout>
  );
};

export default FormforOrderPerDay;
