import InputField from "../../../components/input/InputField";
import { Controller, useForm } from "react-hook-form";
import { ICategoryTypes, IToggleModalTypes } from "../../../../utils/types";
import FormPopup from "../../../components/popup/FormPopup";
import { useWebSocket } from "../../../../api/websocket/WebSocketContext";
import { add_category, edit_category } from "../../../../api/websocket/payload";
import { useEffect } from "react";
import Dropdown from "../../../components/dropdown";
import TextEditor from "../../../components/input/TextEditor";

interface IForm {
  showModal: string;
  toggleModal: (state: IToggleModalTypes) => void;
  editData: ICategoryTypes;
  isEditable: boolean;
}

const Form = (prop: IForm) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm<ICategoryTypes>({
    defaultValues: { description: "", name: "" },
  });
  const { send } = useWebSocket();
  const formContaintCat = {
    title: `${prop.showModal === "edit" ? "Edit" : "Add"} Category`,
    submit: "Submit",
    close: "Cancel",
  };

  const options = [
    { label: "Single", value: "single" },
    { label: "Bundle", value: "bundle" },
  ];

  useEffect(() => {
    if (prop.showModal === "edit") {
      reset(prop.editData); // Resets the form values to prop.editData
    } else {
      reset({ description: "", name: "" }); // Resets the form values to prop.editData
    }
  }, [prop.showModal, reset, prop.editData]);

  const onSubmit = (data: ICategoryTypes) => {
    if (prop.showModal === "add") {
      const payload = { ...add_category, request: data };
      send(payload);
    }
    if (prop.showModal === "edit") {
      const Data = {
        category_id: prop.editData.id,
        is_active: prop.editData.is_active ? "1" : "0",
        name: data.name,
        description: data.description,
        sequence_no: data.sequence_no?.toString(),
      };
      const payload = { ...edit_category, request: Data };
      send(payload);
    }
  };

  

  return (
    <>
      <FormPopup
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        containt={formContaintCat}
        showModal={prop.showModal}
        toggleModal={prop.toggleModal}
      >
        <InputField
          name={"Category Name"}
          placeholder={"Enter Category"}
          register={{
            ...register("name", {
              required: "Category name is required",
              maxLength: {
                value: 256,
                message: "Category name cannot exceed 256 characters",
              },
            }),
          }}
          required
          inputClassName="h-9"
          error={errors.name}
        />
        {prop.showModal==='add'&&<Controller
          name="type"
          control={control}
          rules={{
            required: "Category Type is required",
          }}
          render={({ field }) => (
            <Dropdown
              required
              label="Select Category Type"
              options={options}
              isDynamic
              defaultValue="Category Type"
              value={field.value}
              onChange={field.onChange}
              name={field.name}
              className="py-2"
              errors={errors.type}
            />
          )}
        />}

        {/* <InputField
          name={"Description"}
          placeholder={"Enter Category"}
          register={{
            ...register("description", {
              required: "Category description is required",
              maxLength: {
                value: 2000,
                message:
                  "Category description limit cannot exceed 2000 characters",
              },
            }),
          }}
          required
          error={errors.description}
          useFor="textarea"
        /> */}
        <Controller
            name={"description"}
            control={control}
            defaultValue=""
            rules={{
              required: "Description3 is required",
              minLength: {
                value: 10,
                message: "Description3 must be at least 10 characters long",
              },

              validate: (value) =>
                value.trim().length > 0 || "description cannot be empty",
            }}
            render={({ field }) => (
              <TextEditor field={field} errors={errors.description} />
            )}
          />
        <InputField
          name={"Sequence No."}
          placeholder={"Enter Sequence No"}
          register={{
            ...register("sequence_no", {
              required: "Sequence no. is required",
              pattern: {
                value: /^[0-9]+$/,
                message: "Sequence no. must be a number",
              },
            }),
          }}
          required
          inputClassName="h-9"
          error={errors.sequence_no}
        />
      </FormPopup>
    </>
  );
};

export default Form;
