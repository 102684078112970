import { useState } from "react";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import applogo from "../../asset/images/applogo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { sidebarcontent } from "../../utils/staticdata";

const Sidebar = () => {
  const [menuState, setMenuState] = useState(null);
const {pathname}=useLocation()
const route=pathname.split('/')


  const toggleSubMenu = (index:any) => {
    setMenuState(menuState === index ? null : index);
  };

  const navigate = useNavigate();

  const sidebarToggle = (e:any) => {
    e.preventDefault();
    document.getElementById("panel-sidebar")?.classList.add("hidden");
  };

  function onRoute(e:any,subMenuItem:string){
    e.preventDefault();
    navigate(subMenuItem)
    document.getElementById("panel-sidebar")?.classList.add("hidden");
  }

  

  return (
    <div id="panel-sidebar" className="hidden ml-2 rounded-lg text-slate-700   fixed lg:block lg:relative bg-[#fafafa] lg:w-1/5  w-3/5     sidebar-container overflow-auto z-50">
      <div className="flex relative justify-center text-end cursor-pointer hover:brightness-90 " onClick={()=>navigate('/dashboard')}>
        <div
          className="bg-cover bg-center h-28 w-28 my-4 "
          style={{ backgroundImage: `url(${applogo})` }}
        ></div>
        <MdOutlineClose className="text-3xl lg:hidden text-primary absolute right-2 mt-2" onClick={(e) => sidebarToggle(e)} />
      </div>
      {sidebarcontent?.map((menuItem, index) => (
        <div key={index} className={`${'/'+route[1]===menuItem.route?'bg-[#f5f4f4] ':''}  hover:bg-[#f5f4f4] text-sm hover:border-primary hover:text-primary cursor-pointer  my-2 px-2 py-2 rounded-md mx-1`} onClick={(e) => !menuItem.subMenu?onRoute(e,menuItem.route):menuItem.subMenu && toggleSubMenu(index)}>
          <div className="flex items-center justify-between gap-2" >
           <div className="flex items-center gap-2"> 
            {menuItem.subMenu ? <menuItem.icon/>:<menuItem.icon/>}
            <p className="">{menuItem.title}</p>
            </div>
            {menuItem.subMenu && (menuState === index ? <FaAngleUp /> : <FaAngleDown />)}

          </div>
          {menuState === index && menuItem.subMenu && (
            <div className="border-l-[2px] border-gray-300">
              <div className="rounded-md mt-1 ">
              {menuItem?.subMenu?.map((subMenuItem, subIndex) => (
                <div className="flex  " key={subIndex}>
                  <div className="w-4 mb-1 h-5 rounded-bl-lg border-b-[2px] border-gray-300"></div>
                  <div key={subIndex} onClick={(e) =>onRoute(e,subMenuItem.route)} className={`${route[2]===((subMenuItem.route).split('/')[2])?'bg-primary text-white':''} font-normal flex hover:bg-primary hover:text-white rounded-md items-center border border-transparent hover:border mb-1 p-2  gap-1 w-full`}>
                <subMenuItem.icon/>
                  <p key={subIndex} className="" >
                  {subMenuItem.title}
                  </p>
                </div>
                </div>
                
              ))}
            </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Sidebar;
