import React from "react";
interface ILoader {
  show: boolean;
}
const Loader: React.FC<ILoader> = (prop) => {
  return (
    <>
      {prop.show&&<div className="loader-container">
      <div className="loader"></div>
      </div>}
    </>
  );
};

export default Loader;
