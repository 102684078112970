import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";

interface IDropdownMenu {
  label: string;
  handleChange?: Function;
  setSelect?: React.Dispatch<React.SetStateAction<any>>;
  data: any;
  className?: string;
  setValue?: any;
  name: string;
  setLabel: React.Dispatch<React.SetStateAction<string>>;
  error?: any;
}
const DropdownMenu = ({
  label,
  setLabel,
  className,
  setValue,
  name,
  data,
  error,
}: IDropdownMenu) => {
  // let arr = ["English", "Hindi"];

  const [isOpen, setisOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleSelect = (item: any) => {
    if ("id" in item) setValue(name, item.id);
    else setValue(name, item.name);
    setisOpen((prev) => !prev);
    setLabel(item.name);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Check if the click is outside the dropdown container
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setisOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // useEffect(() => {
  //   setValue(name, data[0]?.name);
  //   setLabel(data[0]?.name);
  // });

  return (
    <>
      <div
        ref={dropdownRef}
        className={`relative flex-col rounded flex justify-center border h-9 z-40 items-center ${className}`}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            setisOpen((prev) => !prev);
          }}
          className={`w-full  h-full rounded  flex items-center   gap-2`}
        >
          <div className="w-4/5  text-sm lg:min-w-0 ">{label}</div>
          <div>
            <IoIosArrowDown className="" />
          </div>
        </button>

        {isOpen && data.length !== 0 && (
          <div
            className="absolute bg-secondary shadow-md w-full top-10 rounded-md p-2 min-w-44"
            style={{ zIndex: 0 }}
          >
            {data?.map((item: any, index: any) => (
              <div
                key={index}
                onClick={() => {
                  handleSelect(item);
                }}
                className="p-2 text-sm hover:bg-gray-50 cursor-pointer hover:rounded text-primary"
              >
                {item.name}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default DropdownMenu;
