import React, { useEffect, useRef, useState } from "react";

interface DropdownProps {
  label: string | number;
  options?: { value: string | number; label: string | number }[];
  value?: any|string | number | (string | number)[]; // Allow for single value or array of values
  onChange: (value: string | number | (string | number)[]) => void;
  name: string;
  onSelect?: (value: string | number | (string | number)[]) => void;
  className?: string;
  errors?: any;
  required?: boolean;
  isDynamic?: boolean;
  defaultValue?: string;
  multiselect?: boolean; // Flag for multi-select
}

const Dropdown: React.FC<DropdownProps> = ({
  errors,
  label,
  options,
  value,
  onChange,
  name,
  onSelect,
  className,
  required,
  isDynamic,
  defaultValue,
  multiselect = false, // Default to single select if not provided
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedValues, setSelectedValues] = useState<(string | number)[]>(value || []);
  const dropdownRef = useRef<HTMLDivElement>(null);


  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
     
    const selectedValue = e.target.value;
    setSelectedValues([selectedValue]);
    onChange(selectedValue);
    if (onSelect) {
      onSelect(selectedValue);
    }
  };

  const handleCheckboxChange = (optionValue: string | number) => {
    const currentIndex = selectedValues.indexOf(optionValue);
    const newSelectedValues = [...selectedValues];

    if (currentIndex === -1) {
      newSelectedValues.push(optionValue);
    } else {
      newSelectedValues.splice(currentIndex, 1);
    }

    setSelectedValues(newSelectedValues);
    onChange(newSelectedValues);

    if (onSelect) {
      onSelect(newSelectedValues);
    }
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Check if the click is outside the dropdown container
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowPopup(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="">
      {label && (
        <label
          className="dropdown-label font-medium text-gray-600 text-sm mb-[1px]"
          htmlFor={name}
        >
          {label}
          {required && <span className="text-red-500"> *</span>}
        </label>
      )}
      <div
        className={`dropdown flex items-center border p-1 rounded text-sm h-fit px-2 ${className}`}
      >
        {multiselect ? (
          <div className="relative">
            <button
              type="button"
              className="focus:outline-none w-full dropdown-select"
              onClick={togglePopup}
            >
              {selectedValues.length > 0 ? (
                selectedValues.map((selectedValue) => (
                  <span key={selectedValue} className="mr-2">
                    {options?.find((opt) => opt.value === selectedValue)?.label}
                  </span>
                ))
              ) : (
                <span className="text-gray-400">-- Select options --</span>
              )}
            </button>
            {showPopup && (
              <div ref={dropdownRef} className="absolute z-10 bg-white p-3 w-auto shadow-md border rounded mt-1 min-w-fit whitespace-nowrap">
                {options?.map((option) => (
                  <label key={option.value} className="block">
                    <input
                      type="checkbox"
                      value={option.value}
                      
                      checked={selectedValues.includes(option.value)}
                      onChange={() => handleCheckboxChange(option.value)}
                      className="mr-2"
                    />
                    {option.label}
                  </label>
                ))}
              </div>
            )}
          </div>
        ) : (
          <select
            id={name}
            className="focus:outline-none w-full dropdown-select"
            value={selectedValues[0] || ""}
            onChange={handleSelectChange}
          >
            {isDynamic && (
              <option value="" disabled>
                 {defaultValue&&defaultValue.charAt(0).toUpperCase()+defaultValue.slice(1)}
              </option>
            )}
            {options?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        )}
      </div>
      {required && (
        <div className="h-4">
          {errors && (
            <p className="text-xs mx-1 text-red-500">{errors.message}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
