import React, { useState } from "react";
import { LuEye, LuSearch } from "react-icons/lu";
import { LuEyeOff } from "react-icons/lu";
import { useLocation } from 'react-router-dom';

interface InputFieldTypes {
  type?: string;
  name: string;
  placeholder: string;
  inputClassName?: string;
  className?: string;
  useFor?: string;
  register?: any;
  error?: any;
  key?: any;
  lableName?: any;
  required?: boolean;
  onEnterClick?: Function
  onChange?: Function
}
const InputField = (prop: InputFieldTypes) => {
  const [Focus, setFocus] = useState(false);
  const [Password, setPassword] = useState(false);
  const location = useLocation();
  const isShippingDateValidationRoute = location.pathname.includes("setting/shipping-date-validation");

  
  return (
    <div className={`${prop.className}`}>
      {prop.useFor !== "search" && (
        <p className="font-medium text-gray-600 text-sm mb-[1px]">
          {prop.name}{prop.required&&<span className="text-red-500"> *</span>}
        </p>
      )}
      {prop.useFor !== "textarea" ? (
        <div>
          <div
            className={`flex border-[1px] rounded  ${prop.inputClassName} ${
              Focus ? "border-[#2737FF]" : "border-gray-200"
            }`}
          >
            {prop.useFor === "search" && <LuSearch className="h-full ml-2" />}
            <input
            onKeyDown={prop.onEnterClick&&prop.onEnterClick}
              placeholder={prop.placeholder}
              type={prop.type === "password" && !Password ? "password" : "text"}
              className={`bg-white rounded focus:outline-none w-full px-2 placeholder:text-sm text-sm  h-auto ${prop.inputClassName}`}
              {...prop.register}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
             
            />
            {prop.type === "password" &&
              (Password ? (
                <LuEyeOff
                  className={`cursor-pointer h-full mr-2`}
                  onClick={() => setPassword(!Password)}
                />
              ) : (
                <LuEye
                  className={`cursor-pointer h-full mr-2`}
                  onClick={() => setPassword(!Password)}
                />
              ))}
          </div>
          {(prop.error && prop.lableName === "Price_select") && (<div className="h-4">
              <p className="text-xs  mx-1  text-red-500">
              price is required
              </p>
          </div>)}
          <div className={`${isShippingDateValidationRoute ? 'h-auto' : 'h-4'} error_msg`}>
            {prop.error && (
              <p className="text-xs  mx-1  text-red-500">
                {prop.error.message}
              </p>
            )}
          </div>
        </div>
      ) : (
        <>
          <div
            className={`bg-white  border-[1px]  rounded  w-full px-2 py-2
          ${prop.inputClassName}`}
          >
            {/* ${Focus ? "border-[#2737FF]" : "border-gray-200"}   */}
            <textarea
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
              name=""
              id=""
              {...prop.register}
              placeholder={prop.placeholder}
              className="rounded focus:outline-none w-full text-sm resize-none placeholder:text-sm"
            />
          </div>
          <div className="h-4 error_msg">
            {prop.error && (
              <p className="text-xs  mx-1  text-red-500">
                {prop.error.message}
              </p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default InputField;
